import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import Cloud from './Cloud';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { FileTypeSelector } from './FileTypeSelector';
import SidebarTitle from './SidebarTitle';
import {
  brandFlaggedToResetSelector,
  brandResultsSelector,
  cloudFileTypeSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setBrandResults, setFlaggedToReset } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { useCurrentUserOrg } from '../../../hooks/useCurrentUserOrg';
import UploadControl from './UploadControl';
import FileUpload from './FileUpload.web';
import { SidebarProps } from './LeftSidebar';

const FILE_TYPES: ('all' | ICloudAsset['fileType'])[] = ['all', 'video', 'image', 'gif', 'audio'];

const additionalRequestFilters = {
  isBrandAsset: true,
};

const BrandSidebar = ({ previewPanelRef, setIsPreviewPanelVisible }: SidebarProps) => {
  const dispatch = useDispatch();

  const brandResults = useSelector(brandResultsSelector);
  const fileType = useSelector(cloudFileTypeSelector);
  const isFlaggedToReset = useSelector(brandFlaggedToResetSelector);

  const isFlaggedToResetRef = useRef(isFlaggedToReset);
  isFlaggedToResetRef.current = !isFlaggedToReset ? isFlaggedToReset : isFlaggedToResetRef.current;

  const userOrg = useCurrentUserOrg();

  const [isUploadVisible, setIsUploadVisible] = useState(false);

  const renderUploadControls = useCallback(() => {
    return <UploadControl isVisible={isUploadVisible} setIsVisible={setIsUploadVisible} />;
  }, [isUploadVisible]);

  const renderFilterControls = useCallback(() => {
    return <FileTypeSelector fileType={fileType} fileTypes={FILE_TYPES} />;
  }, [fileType]);

  useEffect(() => {
    if (isFlaggedToReset) {
      dispatch(
        setFlaggedToReset({
          brand: false,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <>
      <SidebarTitle title='Brand' renderControls={userOrg.content_admin ? renderUploadControls : undefined} />
      {!isUploadVisible ? (
        <Cloud
          cloudResults={brandResults}
          additionalRequestFilters={additionalRequestFilters}
          renderFilterControls={renderFilterControls}
          setCloudResults={setBrandResults}
          setIsPreviewPanelVisible={setIsPreviewPanelVisible}
          isFlaggedToReset={isFlaggedToResetRef.current}
          previewPanelRef={previewPanelRef}
        />
      ) : (
        <FileUpload isBrandAsset setIsUploadVisible={setIsUploadVisible} />
      )}
    </>
  );
};
export default memo(BrandSidebar);
