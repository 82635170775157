import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components/native';
import withRetry from '../../../utils/withRetry';
import Search from './Search.web';
import { searchPexelsWithUrl } from '../../../services/pexels';
import { useDispatch, useSelector } from 'react-redux';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { v4 as uuid } from 'uuid';
import searchPexelsVideos, { searchPexelsPopularVideos } from '../../../services/pexelsVideos';
import VideoSearchItem from './VideoSearchItem.web';
import { getVideoMetadata } from '../../../utils/getVideoMetadata.web';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeTimelineItem,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  setReplacingSelectedTimelineItem,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineItemWithCloudAssetDuration,
} from '../../../store/videoEditor/videoEditor.slice';
import {
  replacingSelectedTimelineItemSelector,
  selectedTimelineItemIdSelector,
  selectedTimelineItemLayerSelector,
  timelineItemSeletor,
  videoResolutionSeletor,
} from '../../../store/videoEditor/videoEditor.selectors';
import { getItemCanvasSize } from '../utils/getItemCanvasSize';
import {
  ICloudAssetVideoCache,
  cloneCloudAssetCacheForVideoTimelineItem,
} from '../../../store/cloudAssets/cloudAssets.cache';
import { STOCK_FILE_TYPES } from '../videoEditor.constants';
import { getVideoThumbnail } from '../../../utils/introMedia/useVideoThumbnail/useVideoThumbnail.web';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import { StockFilterControls } from './StockFilterControls.web';
import { IVideoConfig } from '../../../store/videoEditor/videoEditor.types';
import { getResizeTarget } from '../utils/getResizeTarget';
import {
  stockFileTypeSelector,
  stockVideoResultsSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { useOrientation } from './utils/useOrientation';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { setStockVideoResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { FileTypeSelector } from './FileTypeSelector';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { PreviewVideo } from './preview/PreviewVideo';

type Props = {
  setIsPreviewPanelVisible: (isVisible: boolean) => void;
  previewPanelRef: RefObject<HTMLDivElement>;
};
const StockVideos = ({ previewPanelRef, setIsPreviewPanelVisible }: Props) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();
  const fileType = useSelector(stockFileTypeSelector);

  const resolution = useSelector(videoResolutionSeletor);
  const stockVideoResults = useSelector(stockVideoResultsSelector);
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const selectedTimelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const selectedTimelineItemLayer = useSelector(selectedTimelineItemLayerSelector);
  const replacingSelectedTimelineItem = useSelector(replacingSelectedTimelineItemSelector);

  const { orientation, setOrientation } = useOrientation();

  const requestIdRef = useRef(0);
  const keywordRef = useRef('');

  const handleSearch = useCallback(
    async ({
      query,
      withReset,
      withDelayedReset,
    }: {
      query: string;
      withReset?: boolean;
      withDelayedReset?: boolean;
    }) => {
      keywordRef.current = query;
      const requestId = ++requestIdRef.current;
      try {
        dispatch(
          setStockVideoResults({
            data: withReset ? null : stockVideoResults.data,
            next: withReset ? null : stockVideoResults.next,
            isLoading: true,
            error: null,
          }),
        );

        const service =
          stockVideoResults.next && !(withReset || withDelayedReset)
            ? () => searchPexelsWithUrl(stockVideoResults.next)
            : query
            ? () => searchPexelsVideos({ query, orientation })
            : () => searchPexelsPopularVideos();

        const result = await withRetry(service, {
          errorContext: {
            data: {
              action: 'StockVideos.handleSearch',
            },
          },
        });

        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        dispatch(
          setStockVideoResults({
            data: withReset || withDelayedReset ? result.videos : [...(stockVideoResults.data || []), ...result.videos],
            next: result.next_page,
            isLoading: false,
          }),
        );
      } catch (error) {
        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        dispatch(
          logError({
            event: 'StockVideos.handleSearch: error',
            data: {
              error,
            },
          }),
        );

        dispatch(
          setStockVideoResults({
            data: withDelayedReset ? [] : stockVideoResults.data,
            next: withDelayedReset ? null : stockVideoResults.next,
            isLoading: false,
            error,
          }),
        );
      }
    },
    [dispatch, isMountedRef, orientation, stockVideoResults.data, stockVideoResults.next],
  );

  const [selectedItem, setSelectedItem] = useState<any>(null);

  const getItemKey = useCallback((item) => `${item.id}`, []);

  const handleVideoSelect = useCallback(
    (item) => {
      setIsPreviewPanelVisible(true);
      setSelectedItem(item);
    },
    [setIsPreviewPanelVisible],
  );

  const handleVideoUnselect = useCallback(() => {
    setIsPreviewPanelVisible(false);
    setSelectedItem(null);
  }, [setIsPreviewPanelVisible]);

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <VideoSearchItem
          url={item.image || null}
          item={item}
          // isLoading={isUploadingMap[item.id]}
          onSelect={handleVideoSelect}
        />
      );
    },
    [handleVideoSelect],
  );

  const pexelsVideo = useMemo(() => {
    if (selectedItem) {
      const resizeTarget = getResizeTarget(resolution);

      const _pexelsVideo = getPexelsVideo({
        videoFiles: selectedItem.video_files,
        resolution: resizeTarget,
      });

      return _pexelsVideo;
    }

    return null;
  }, [resolution, selectedItem]);

  const handleUseVideo = useCallback(
    async ({ withReplace }: { withReplace?: boolean } = {}) => {
      if (!pexelsVideo) {
        return;
      }

      const processId = uuid();

      setIsPreviewPanelVisible(false);
      setSelectedItem(null);
      dispatch(setReplacingSelectedTimelineItem(false));

      const timelineItemId = uuid();
      const timelineItem = {
        id: timelineItemId,
        type: 'video' as const,
        x: 0,
        y: 0,
      };

      let start: number;
      let layerId: string;
      const isReplacing = withReplace && selectedTimelineItem && selectedTimelineItemLayer;
      if (isReplacing) {
        if (timelineItem.type === selectedTimelineItem.type) {
          start = selectedTimelineItem.start;
          layerId = selectedTimelineItemLayer.id;
        }

        dispatch(
          removeTimelineItem({
            timelineItemId: selectedTimelineItemId,
            fromTimeline: true,
          }),
        );
      }

      dispatch(
        addToTimeline({
          timelineItem,
          duration: selectedItem.duration,
          start,
          layerId,
        }),
      );
      dispatch(
        updateTimelineItemLayer({
          timelineItemId,
        }),
      );
      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      const processingLabel = 'Stock video';

      const handleFail = (error: any) => {
        dispatch(
          setProcessingTimelineItemState({
            timelineItemId,
            label: processingLabel,
            status: 'ERROR',
            retryFunctionId,
            error: {
              details: error,
            },
          }),
        );
      };

      const retryFunctionId = uuid();
      const applyItemFunction = async () => {
        try {
          dispatch(
            setProcessingTimelineItemState({
              timelineItemId,
              label: processingLabel,
              status: 'PROCESSING',
              retryFunctionId,
            }),
          );

          // native
          // const { promise } = getLocalUrlPath(selectedItem.video_files[0].link, {
          //   withClearCache: false,
          //   localPath: 'cloudAssets', // to think about the path for each file
          // });
          // const localPath = await promise;

          const response = await fetch(pexelsVideo.link);
          const blob = await response.blob();

          const previewImageFile = await getVideoThumbnail(blob);

          const fileMeta = await getVideoMetadata({ blob });
          const fileName = pexelsVideo.link.split('/').pop().split('?')[0].split('#')[0];
          fileMeta.name = fileName;

          const file = new File([blob], fileName, {
            type: blob.type, // Preserves the MIME type
            lastModified: new Date().getTime(), // Converts the date to a timestamp
          });

          dispatch(
            createCloudAsset({
              processId,
              fileType: 'video',
              fileMeta,
              file,
              sourceMeta: {
                keyword: keywordRef.current,
              },
              originalSrc: 'pexels',
              originalData: selectedItem,
              previewImageFile,
              onCacheReady: async ({ cloudAsset, cloudAssetCache }) => {
                try {
                  dispatch(
                    removeUndoRedoById({
                      id: undoId,
                    }),
                  );

                  dispatch(
                    setProcessingTimelineItemState({
                      timelineItemId,
                      label: processingLabel,
                      status: 'UPLOADING',
                    }),
                  );

                  delete retryFunctions[retryFunctionId];

                  const isOnTimeline = getIsItemOnTimeline(timelineItemId);

                  if (!isOnTimeline) {
                    return;
                  }

                  const canvasSize = getItemCanvasSize({
                    width: fileMeta.width,
                    height: fileMeta.height,
                    resolution,
                    scaleUp: true,
                  });

                  await cloneCloudAssetCacheForVideoTimelineItem(
                    cloudAssetCache as ICloudAssetVideoCache,
                    timelineItemId,
                  );

                  dispatch(
                    updateTimelineItem({
                      id: timelineItemId,
                      cloudAssetId: cloudAsset.id,
                      width: canvasSize.width,
                      height: canvasSize.height,
                    }),
                  );

                  if (!(isReplacing && layerId)) {
                    dispatch(
                      updateTimelineItemWithCloudAssetDuration({
                        timelineItemId,
                      }),
                    );
                  }

                  dispatch(updateCanvas({}));
                  dispatch(
                    addUndo({
                      id: undoId,
                    }),
                  );
                  dispatch(saveVideo({}));
                } catch (error) {
                  handleFail(error);
                }
              },
              onCreate: () => {
                dispatch(
                  setProcessingTimelineItemState({
                    timelineItemId,
                    label: processingLabel,
                    status: 'DONE',
                  }),
                );
                setTimeout(() => {
                  dispatch(
                    deleteProcessingTimelineItemState({
                      timelineItemId,
                    }),
                  );
                }, 5000);
              },
              onFail: handleFail,
            }),
          );
        } catch (error) {
          handleFail(error);
        }
      };
      retryFunctions[retryFunctionId] = applyItemFunction;
      applyItemFunction();
    },
    [
      dispatch,
      pexelsVideo,
      resolution,
      selectedItem,
      selectedTimelineItem,
      selectedTimelineItemId,
      selectedTimelineItemLayer,
      setIsPreviewPanelVisible,
    ],
  );

  const handleReplaceItem = useCallback(() => {
    handleUseVideo({ withReplace: true });
  }, [handleUseVideo]);

  const renderFilterControls = useCallback(
    ({ query }: { query: string }) => {
      return (
        <>
          <FileTypeSelector fileType={fileType} fileTypes={STOCK_FILE_TYPES} />
          <StockFilterControls query={query} orientation={orientation} setOrientation={setOrientation} />
        </>
      );
    },
    [fileType, orientation, setOrientation],
  );

  useEffect(() => {
    return () => {
      setIsPreviewPanelVisible(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Search
        results={stockVideoResults.data}
        isLoading={stockVideoResults.isLoading}
        isError={!!stockVideoResults.error}
        hasMore={!!stockVideoResults.next}
        withSearchInput
        layout='masonry'
        renderFilterControls={renderFilterControls}
        onSearch={handleSearch}
        renderItem={renderItem}
        getItemKey={getItemKey}
      />
      {selectedItem &&
        createPortal(
          <PreviewVideo
            url={pexelsVideo.link}
            onAdd={handleUseVideo}
            onReplace={replacingSelectedTimelineItem ? handleReplaceItem : undefined}
            onClose={handleVideoUnselect}
          />,
          previewPanelRef.current,
        )}
    </>
  );
};
export default StockVideos;

const getPexelsVideo = ({
  videoFiles,
  resolution,
}: {
  videoFiles: any[];
  resolution: Partial<IVideoConfig['resolution']>;
}) => {
  const videoFilesSorted = [...videoFiles].sort((a, b) => a.height - b.height);

  const videoFile = videoFilesSorted.find((file) => {
    // we can take a bigger video, since afterwards it will be resized to our target
    if (
      (resolution.height && file.height >= resolution.height) ||
      (resolution.width && file.width >= resolution.width)
    ) {
      return file;
    }

    return false;
  });

  if (!videoFile) {
    return videoFilesSorted.slice(-1)[0];
  }

  return videoFile;
};

export const S = {
  PreviewContainer: styled.View`
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.transparentBlack2};
  `,
};
