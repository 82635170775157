import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAddToTimeline,
  ICreateExportedVideoPayload,
  IExportedVideo,
  IInitialState,
  IGhostTimelineItemMeta,
  ITimelineItemMeta,
  IUpdateCanvas,
  IVideo,
  IVideoConfig,
  ISetGhostTimelineLayerPrevState,
  ITimelineGhostItemHoverTimelineLayer,
  ITimelineGhostItemHoverTimelineLayerPlaceholder,
  IRemoveTimelineItem,
  ISplitTimelineItem,
  ITimelineItem,
  ITrimTimelineItemStart,
  ITrimTimelineItemEnd,
  ITrimTimelineItemMeta,
  IDraggingCanvasItemMeta,
  IUpdateTimelineItemLayer,
  ILoadVideoPayload,
  ISaveVideo,
  TSearchVideo,
  IStartNewVideo,
  ICloneExistingVideoPayload,
} from './videoEditor.types';
import { timelineLayerAcceptByType, videoEditorData } from './videoEditor.data';
import store from '..';
import { v4 as uuid } from 'uuid';
import { IDataTableResult } from '../../types/common';
import { MAX_UNDO_REDO_STACK_SIZE } from '../../screens/videoEditor/videoEditor.constants';
import { cloneDeep } from 'lodash';

const sliceName = 'VIDEO_EDITOR';

const getNewVideoData = ({
  resolution = {
    width: 1080,
    height: 1920,
  },
} = {}): IVideo => {
  return {
    config: {
      timelineLayers: [
        {
          id: uuid(),
          timeline: [],
          // type: ['video'],
        },
      ],
      itemsMap: {},
      resolution,
      duration: 0,
    },
    assetIds: [],
    usedInBiteIds: [],
    isDraft: true,
    orgId: null,
    creatorId: null,
    id: null,
  };
};

// @ts-ignore
window.getStore = () => store;
export const initialState: IInitialState = {
  existingVideos: {
    data: null,
    page: 0,
    isLoading: false,
    hasMore: false,
    loadingError: null,
  },
  video: {
    data: null,
    isSaving: false,
    isAutoSaving: false,
    savingError: null,
    isLoading: false,
    loadingError: null,
  },
  exportedVideo: {
    data: null,
    isLoading: false,
    error: null,
  },
  timelineHeight: null,
  isTimelineExpanded: true,
  timelineScale: 0,
  currentSeconds: 0,
  canvasItems: [],
  isPlaying: false,
  selectedTimelineLayerId: null,
  selectedTimelineItemId: null,
  highlightSelectedTimelineItem: false,
  replacingSelectedTimelineItem: false,
  ghostTimelineItemMeta: null,
  ghostTimelineLayerPrevState: null,
  draggingCanvasItemMeta: null,
  trimTimelineItemMeta: null,
  showTimelineItemGeneratedMeta: null,
  isSplitMode: false,
  copiedTimelineItem: null,
  undoStack: [],
  nextUndo: null,
  redoStack: [],
  recordingTimelineItemId: null,
  processingTimelineItems: [],
  acceptTypesByTimelineLayerId: {},
  timelineSnap: null,
  keepRatio: true,
};

const analyticsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    // existing videos
    loadExistingVideos: (state) => {
      state.existingVideos.data = null;

      state.existingVideos.isLoading = true;
      state.existingVideos.loadingError = null;
    },
    loadExistingVideosNextPage: (state) => {
      state.existingVideos.page++;

      state.existingVideos.isLoading = true;
      state.existingVideos.loadingError = null;
    },
    applyExistingVideosDtResult: (
      state,
      { payload }: PayloadAction<IDataTableResult<{ video: TSearchVideo }, undefined>>,
    ) => {
      state.existingVideos.data = state.existingVideos.data || [];
      state.existingVideos.data = [...state.existingVideos.data, ...payload.results.map(({ video }) => video)];

      state.existingVideos.hasMore = payload.page < payload.totalPages - 1;

      state.existingVideos.isLoading = false;
      state.existingVideos.loadingError = null;
    },
    setExistingVideosError: (state, { payload }: PayloadAction<any>) => {
      state.existingVideos.isLoading = false;
      state.existingVideos.loadingError = payload;
    },

    // video
    startNewVideo: (_, { payload: { resolution } }: PayloadAction<IStartNewVideo>) => {
      const newState = cloneDeep(initialState);

      newState.video.data = getNewVideoData({ resolution });

      newState.video.isSaving = false;
      newState.video.isAutoSaving = false;
      newState.video.savingError = null;
      newState.video.isLoading = true;
      newState.video.loadingError = null;

      return newState;
    },

    // loading video
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loadVideo: (_, { payload }: PayloadAction<ILoadVideoPayload>) => {
      const newState = cloneDeep(initialState);

      newState.video.data = null;

      newState.video.isSaving = false;
      newState.video.isAutoSaving = false;
      newState.video.savingError = null;
      newState.video.isLoading = true;
      newState.video.loadingError = null;

      return newState;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cloneExistingVideo: (_, { payload }: PayloadAction<ICloneExistingVideoPayload>) => {
      const newState = cloneDeep(initialState);

      newState.video.data = null;

      newState.video.isSaving = false;
      newState.video.isAutoSaving = false;
      newState.video.savingError = null;
      newState.video.isLoading = true;
      newState.video.loadingError = null;

      return newState;
    },

    setVideo: (state, { payload }: PayloadAction<IVideo>) => {
      state.video.data = payload;
    },
    setVideoLoaded: (state) => {
      state.video.isSaving = false;
      state.video.isAutoSaving = false;
      state.video.savingError = null;
      state.video.isLoading = false;
      state.video.loadingError = null;
    },
    setVideoLoadingError: (state, { payload }: PayloadAction<any>) => {
      state.video.isSaving = false;
      state.video.isAutoSaving = false;
      state.video.savingError = null;
      state.video.isLoading = false;
      state.video.loadingError = payload;
    },

    // saving video
    saveVideo: (state, { payload: {} }: PayloadAction<ISaveVideo>) => {
      state.video.savingError = null;
      state.video.isLoading = false;
      state.video.loadingError = null;
    },
    setVideoSavingError: (state, { payload }: PayloadAction<any>) => {
      state.video.isSaving = false;
      state.video.isAutoSaving = false;
      state.video.savingError = payload;
      state.video.isLoading = false;
      state.video.loadingError = null;
    },
    setIsVideoSaving: (
      state,
      {
        payload,
      }: PayloadAction<{
        isSaving: boolean;
        isAutoSaving: boolean;
      }>,
    ) => {
      state.video.isSaving = payload.isSaving;
      state.video.isAutoSaving = payload.isAutoSaving;
      state.video.savingError = null;
      state.video.isLoading = false;
      state.video.loadingError = null;
    },

    // exportedVideo
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createExportedVideo: (state, { payload }: PayloadAction<ICreateExportedVideoPayload>) => {
      state.exportedVideo.data = null;
      state.exportedVideo.isLoading = true;
      state.exportedVideo.error = null;
    },
    setExportedVideo: (state, { payload }: PayloadAction<IExportedVideo>) => {
      state.exportedVideo.data = payload;
      state.exportedVideo.isLoading = false;
      state.exportedVideo.error = null;
    },
    setExportedVideoError: (state, { payload }: PayloadAction<any>) => {
      state.exportedVideo.error = payload;
      state.exportedVideo.isLoading = false;
    },

    updateVideoConfig: (state, { payload }: PayloadAction<Partial<IVideoConfig>>) => {
      state.video.data!.config = {
        ...state.video.data!.config,
        ...payload,
      };
    },
    setIsDraft: (state, { payload }: PayloadAction<boolean>) => {
      state.video.data!.isDraft = payload;
    },
    setCanvasItems: (state, { payload }: PayloadAction<ITimelineItemMeta[]>) => {
      state.canvasItems = payload;
    },
    setUsedInBiteIds: (state, { payload }: PayloadAction<number[]>) => {
      state.video.data.usedInBiteIds = payload;
    },
    setIsPlaying: (state, { payload }: PayloadAction<boolean>) => {
      state.isPlaying = payload;

      if (videoEditorData.startPlayingTs && !state.isPlaying) {
        state.currentSeconds += (Date.now() - videoEditorData.startPlayingTs) / 1000;
        videoEditorData.startPlayingTs = null;
      }
    },
    setCurrentSeconds: (state, { payload }: PayloadAction<number>) => {
      state.currentSeconds = payload;
    },
    setSelectedTimelineLayerId: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedTimelineLayerId = payload;

      state.video.data!.config.timelineLayers.some((layer) => {
        if (state.selectedTimelineLayerId !== layer.id) {
          return false;
        }

        const isChildItemSelected = layer.timeline.some(
          (timelineItemId) => timelineItemId === state.selectedTimelineItemId,
        );

        if (!isChildItemSelected) {
          state.selectedTimelineItemId = null;
        }

        return isChildItemSelected;
      });
    },
    setSelectedTimelineItemId: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedTimelineItemId = payload;
    },
    setHighlightSelectedTimelineItem: (
      state,
      { payload }: PayloadAction<IInitialState['highlightSelectedTimelineItem']>,
    ) => {
      state.highlightSelectedTimelineItem = payload;
    },
    setReplacingSelectedTimelineItem: (
      state,
      { payload }: PayloadAction<IInitialState['replacingSelectedTimelineItem']>,
    ) => {
      state.replacingSelectedTimelineItem = payload;
    },

    // drag and drop
    setGhostTimelineItemMeta: (state, { payload }: PayloadAction<IGhostTimelineItemMeta | null>) => {
      state.ghostTimelineItemMeta = payload;
    },
    setGhostTimelineLayerPrevState: (state, { payload }: PayloadAction<ISetGhostTimelineLayerPrevState | null>) => {
      state.ghostTimelineLayerPrevState = payload;
    },
    setDraggingCanvasItemMeta: (state, { payload }: PayloadAction<IDraggingCanvasItemMeta | null>) => {
      state.draggingCanvasItemMeta = payload;
    },

    // trim
    setTrimTimelineItemMeta: (state, { payload }: PayloadAction<ITrimTimelineItemMeta | null>) => {
      state.trimTimelineItemMeta = payload;
    },

    setIsSplitMode: (state, { payload }: PayloadAction<boolean>) => {
      state.isSplitMode = payload;
    },
    setTimelineScale: (state, { payload }: PayloadAction<number>) => {
      state.timelineScale = payload;
    },
    setCopiedTimelineItem: (state, { payload }: PayloadAction<ITimelineItem>) => {
      state.copiedTimelineItem = payload;
    },
    setRecordingTimelineItemId: (state, { payload }: PayloadAction<string | null>) => {
      state.recordingTimelineItemId = payload;
    },
    setProcessingTimelineItemState: (
      state,
      {
        payload,
      }: PayloadAction<{
        timelineItemId: ITimelineItem['id'];
        label: string;
        status: 'PROCESSING' | 'UPLOADING' | 'DONE' | 'ERROR';
        error?: {
          code?: 'FILE_SIZE_LIMIT_EXCEEDED';
          details?: any;
        };
        retryFunctionId?: string;
      }>,
    ) => {
      const index = state.processingTimelineItems.findIndex(
        ({ timelineItemId }) => timelineItemId === payload.timelineItemId,
      );

      const newState = {
        timelineItemId: payload.timelineItemId,
        label: payload.label,
        status: payload.status,
        retryFunctionId: payload.retryFunctionId,
        error: payload.error || null,
      };

      if (index !== -1) {
        state.processingTimelineItems[index] = newState;
        return;
      }

      state.processingTimelineItems.push(newState);
    },
    deleteProcessingTimelineItemState: (
      state,
      {
        payload,
      }: PayloadAction<{
        timelineItemId: ITimelineItem['id'];
      }>,
    ) => {
      const index = state.processingTimelineItems.findIndex(
        ({ timelineItemId }) => timelineItemId === payload.timelineItemId,
      );

      if (index !== -1) {
        state.processingTimelineItems.splice(index, 1);
      }
    },

    updateTimelineItem: (state, { payload }: PayloadAction<Partial<ITimelineItem>>) => {
      const { id } = payload;

      const timelineItem: ITimelineItem = state.video.data.config.itemsMap[id];

      if (!timelineItem) {
        return;
      }

      const updatedTimelineItem = {
        ...timelineItem,
        ...payload,
      };

      Object.entries(payload).forEach(([key, value]) => {
        if (value === undefined) {
          delete updatedTimelineItem[key];
        }
      });

      state.video.data.config.itemsMap[timelineItem.id] = updatedTimelineItem;
      state.video.data.config.duration = Math.max(updatedTimelineItem.end, state.video.data.config.duration);
    },
    updateTimelineItemInItemsMap: (state, { payload: timelineItem }: PayloadAction<ITimelineItem>) => {
      const videoConfig: IVideoConfig = state.video.data!.config;

      const updatedItemsMap = {
        ...videoConfig.itemsMap,
        [timelineItem.id]: timelineItem,
      };

      state.video.data!.config = {
        ...videoConfig,
        itemsMap: updatedItemsMap,
        duration: Math.max(timelineItem.end, videoConfig.duration),
      };
    },

    updateAcceptTypesForTimelineLayersInConfig: (
      state,
      { payload: { config } }: PayloadAction<{ config: IVideoConfig }>,
    ) => {
      const acceptTypesByTimelineLayerId: Record<string, ITimelineItem['type'][]> = {};

      for (let layer of config.timelineLayers) {
        const firstTimelineItemId = layer.timeline[0];
        const firstTimelineItem = firstTimelineItemId ? config.itemsMap[firstTimelineItemId] : null;

        acceptTypesByTimelineLayerId[layer.id] = firstTimelineItem
          ? timelineLayerAcceptByType[firstTimelineItem.type]
          : state.acceptTypesByTimelineLayerId[layer.id] || timelineLayerAcceptByType.video;
      }

      state.acceptTypesByTimelineLayerId = acceptTypesByTimelineLayerId;
    },
    setShowTimelineItemGeneratedMeta: (
      state,
      { payload }: PayloadAction<IInitialState['showTimelineItemGeneratedMeta']>,
    ) => {
      state.showTimelineItemGeneratedMeta = payload;
    },
    setAcceptTypesByTimelineLayerId: (
      state,
      { payload }: PayloadAction<{ timelineLayerId: string; acceptTypes: ITimelineItem['type'][] | null }>,
    ) => {
      if (!state.acceptTypesByTimelineLayerId) {
        delete state.acceptTypesByTimelineLayerId[payload.timelineLayerId];
        return;
      }

      state.acceptTypesByTimelineLayerId[payload.timelineLayerId] = payload.acceptTypes;
    },

    // undo/redo
    addUndo: (state, props: PayloadAction<{ id?: string }>) => {
      let {
        payload: { id },
      } = props || {};

      id = id || uuid();

      if (state.nextUndo) {
        if (state.undoStack.length >= MAX_UNDO_REDO_STACK_SIZE) {
          state.undoStack.shift();
        }

        state.undoStack.push(state.nextUndo);
      }

      state.nextUndo = {
        id,
        video: state.video.data,
      };
      state.redoStack = [];
    },
    undo: (state) => {
      let lastUndo = state.undoStack.pop();

      if (!lastUndo) {
        return;
      }

      if (state.nextUndo) {
        state.redoStack.push(state.nextUndo);
      }

      lastUndo = {
        ...lastUndo,
        video: getUndoRedoWithMergedItemsMap(state, lastUndo.video),
      };

      state.nextUndo = {
        ...lastUndo,
      };
      state.video.data = lastUndo.video;
    },
    redo: (state) => {
      let lastRedo = state.redoStack.pop();

      if (!lastRedo) {
        return;
      }

      lastRedo = {
        ...lastRedo,
        video: getUndoRedoWithMergedItemsMap(state, lastRedo.video),
      };

      state.undoStack.push(state.nextUndo!);
      state.video.data = lastRedo.video;
      state.nextUndo = lastRedo;
    },
    removeUndoRedoById: (state, { payload: { id } }: PayloadAction<{ id: string }>) => {
      state.undoStack = state.undoStack.filter((undo) => undo.id !== id);
      state.redoStack = state.redoStack.filter((redo) => redo.id !== id);
      if (state.nextUndo?.id === id) {
        state.nextUndo = null;
      }
    },

    setIsTimelineExpanded: (state, { payload }: PayloadAction<boolean>) => {
      state.isTimelineExpanded = payload;
    },
    setTimelineHeight: (state, { payload }: PayloadAction<number | null>) => {
      state.timelineHeight = payload;
    },

    setTimelineSnap: (state, { payload }: PayloadAction<IInitialState['timelineSnap'] | null>) => {
      state.timelineSnap = payload;
    },

    setKeepRatio: (state, { payload }: PayloadAction<boolean>) => {
      state.keepRatio = payload;
    },

    // clean
    cleanVideoEditor: () => {
      return initialState;
    },
  },
});
export default analyticsSlice.reducer;

const getUndoRedoWithMergedItemsMap = (state: IInitialState, undoRedoState: IVideo) => {
  return {
    ...undoRedoState,
    config: {
      ...undoRedoState.config,
      itemsMap: {
        ...state.video.data!.config.itemsMap,
        ...undoRedoState.config.itemsMap,
      },
    },
  };
};

export const {
  // existing videos
  loadExistingVideos,
  loadExistingVideosNextPage,
  applyExistingVideosDtResult,
  setExistingVideosError,
  // video
  startNewVideo,
  loadVideo,
  cloneExistingVideo,
  setVideoLoadingError,
  setVideo,
  setVideoLoaded,
  saveVideo,
  setVideoSavingError,
  setIsVideoSaving,

  // exportedVideo
  createExportedVideo,
  setExportedVideo,
  setExportedVideoError,

  // videoConfig
  updateVideoConfig,
  setIsDraft,
  setCanvasItems,
  setUsedInBiteIds,
  setIsPlaying,
  setCurrentSeconds,
  setSelectedTimelineLayerId,
  setSelectedTimelineItemId,
  setHighlightSelectedTimelineItem,
  setReplacingSelectedTimelineItem,
  setGhostTimelineItemMeta,
  setGhostTimelineLayerPrevState,
  setDraggingCanvasItemMeta,
  setTrimTimelineItemMeta,
  setIsSplitMode,
  setTimelineScale,
  setCopiedTimelineItem,
  setRecordingTimelineItemId,
  setProcessingTimelineItemState,
  deleteProcessingTimelineItemState,
  setAcceptTypesByTimelineLayerId,
  updateTimelineItem,
  updateTimelineItemInItemsMap,
  updateAcceptTypesForTimelineLayersInConfig,

  setShowTimelineItemGeneratedMeta,

  // UI state
  setIsTimelineExpanded,
  setTimelineHeight,

  setTimelineSnap,
  setKeepRatio,

  // undo/redo
  addUndo,
  undo,
  redo,
  removeUndoRedoById,

  // clean
  cleanVideoEditor,
} = analyticsSlice.actions;

export const addToTimeline = createAction<IAddToTimeline>(`${sliceName}/addToTimeline`);
export const removeEmptyTimelineLayers = createAction<IAddToTimeline>(`${sliceName}/removeEmptyTimelineLayers`);
export const updateTimelineItemLayer = createAction<IUpdateTimelineItemLayer>(`${sliceName}/updateTimelineItemLayer`);
export const updateCanvas = createAction<IUpdateCanvas>(`${sliceName}/updateCanvas`);
export const playVideo = createAction(`${sliceName}/playVideo`);
export const timelineGhostItemHoverTimelineLayer = createAction<ITimelineGhostItemHoverTimelineLayer>(
  `${sliceName}/timelineGhostItemHoverTimelineLayer`,
);
export const timelineGhostItemHoverTimelineLayerPlaceholder =
  createAction<ITimelineGhostItemHoverTimelineLayerPlaceholder>(
    `${sliceName}/timelineGhostItemHoverTimelineLayerPlaceholder`,
  );
export const trimTimelineItemStart = createAction<ITrimTimelineItemStart>(`${sliceName}/trimTimelineItemStart`);
export const trimTimelineItemEnd = createAction<ITrimTimelineItemEnd>(`${sliceName}/trimTimelineItemEnd`);
export const removeTimelineItem = createAction<IRemoveTimelineItem>(
  `${sliceName}/removeTimelineItem
  `,
);
export const clearStateForTimelineItem = createAction<{
  timelineItemId: ITimelineItem['id'];
}>(
  `${sliceName}/clearStateForTimelineItem
  `,
);

export const splitTimelineItem = createAction<ISplitTimelineItem>(
  `${sliceName}/splitTimelineItem
  `,
);

export const resetGhostTimelineLayerPrevState = createAction<ITimelineGhostItemHoverTimelineLayer>(
  `${sliceName}/resetGhostTimelineLayerPrevState`,
);
export const pasteTimelineItem = createAction(`${sliceName}/pasteTimelineItem`);
// export const updateTimelineItem = createAction<Partial<ITimelineItem>>(`${sliceName}/updateTimelineItem`);
export const updateTimelineItemWithCloudAssetDuration = createAction<{ timelineItemId: ITimelineItem['id'] }>(
  `${sliceName}/updateTimelineItemWithCloudAssetDuration`,
);
export const refreshCanvas = createAction(`${sliceName}/refreshCanvas`);
export const updateTimelineMeta = createAction(`${sliceName}/updateTimelineMeta`);
export const setGeneratedSubtitlesEnabledState = createAction<{ enabled: boolean }>(
  `${sliceName}/setGeneratedSubtitlesEnabledState`,
);
export const updateVideoDuration = createAction(`${sliceName}/updateVideoDuration`);
export const webVideoExport = createAction(`${sliceName}/webVideoExport`);
