import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import withRetry from '../../../utils/withRetry';
import Search from './Search.web';
import searchPexels, { searchPexelsCuratedImages, searchPexelsWithUrl } from '../../../services/pexels';
import { useDispatch, useSelector } from 'react-redux';
import { createCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { v4 as uuid } from 'uuid';
import ImageSearchItem from './ImageSearchItem.web';
import { getImageMetadata } from '../../../utils/getImageMetadata';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeTimelineItem,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  setReplacingSelectedTimelineItem,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
} from '../../../store/videoEditor/videoEditor.slice';
import {
  replacingSelectedTimelineItemSelector,
  selectedTimelineItemIdSelector,
  selectedTimelineItemLayerSelector,
  timelineItemSeletor,
  videoResolutionSeletor,
} from '../../../store/videoEditor/videoEditor.selectors';
import { getItemCanvasSize } from '../utils/getItemCanvasSize';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import { StockFilterControls } from './StockFilterControls.web';
import {
  stockFileTypeSelector,
  stockImageResultsSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { useOrientation } from './utils/useOrientation';
import { setStockImageResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { logError } from '../../../store/appActivity/appActivity.slice';
import { STOCK_FILE_TYPES } from '../videoEditor.constants';
import { FileTypeSelector } from './FileTypeSelector';
import { useIsMounted } from '../../../hooks/useIsMounted';
import { PreviewImage } from './preview/PreviewImage';

type Props = {
  setIsPreviewPanelVisible: (isVisible: boolean) => void;
  previewPanelRef: RefObject<HTMLDivElement>;
};
const StockImages = ({ previewPanelRef, setIsPreviewPanelVisible }: Props) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMounted();

  const resolution = useSelector(videoResolutionSeletor);
  const stockImageResults = useSelector(stockImageResultsSelector);
  const fileType = useSelector(stockFileTypeSelector);
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const selectedTimelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const selectedTimelineItemLayer = useSelector(selectedTimelineItemLayerSelector);
  const replacingSelectedTimelineItem = useSelector(replacingSelectedTimelineItemSelector);

  const { orientation, setOrientation } = useOrientation();

  const requestIdRef = useRef(0);
  const keywordRef = useRef('');

  const handleSearch = useCallback(
    async ({
      query,
      withReset,
      withDelayedReset,
    }: {
      query: string;
      withReset?: boolean;
      withDelayedReset?: boolean;
    }) => {
      keywordRef.current = query;
      const requestId = ++requestIdRef.current;

      try {
        dispatch(
          setStockImageResults({
            data: withReset ? null : stockImageResults.data,
            next: withReset ? null : stockImageResults.next,
            isLoading: true,
            error: null,
          }),
        );

        const service =
          stockImageResults.next && !(withReset || withDelayedReset)
            ? () => searchPexelsWithUrl(stockImageResults.next)
            : query
            ? () => searchPexels(query, { resultsPerPage: 30, orientation })
            : () => searchPexelsCuratedImages();

        const result = await withRetry(service, {
          errorContext: {
            data: {
              action: 'StockImages.handleSearch',
            },
          },
        });

        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        dispatch(
          setStockImageResults({
            data: withReset || withDelayedReset ? result.photos : [...(stockImageResults.data || []), ...result.photos],
            next: result.next_page,
            isLoading: false,
          }),
        );
      } catch (error) {
        if (requestId !== requestIdRef.current || !isMountedRef.current) {
          return;
        }

        dispatch(
          logError({
            event: 'StockImages.handleSearch: error',
            data: {
              error,
            },
          }),
        );

        dispatch(
          setStockImageResults({
            data: withDelayedReset ? [] : stockImageResults.data,
            next: withDelayedReset ? null : stockImageResults.next,
            isLoading: false,
            error,
          }),
        );
      }
    },
    [dispatch, isMountedRef, orientation, stockImageResults.data, stockImageResults.next],
  );

  const getItemKey = useCallback((item) => item.id, []);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  const handleUseItem = useCallback(
    async ({ withReplace }: { withReplace?: boolean } = {}) => {
      const processId = uuid();
      const item = selectedItem;

      setIsPreviewPanelVisible(false);
      setSelectedItem(null);
      dispatch(setReplacingSelectedTimelineItem(false));

      const timelineItemId = uuid();
      const timelineItem = {
        id: timelineItemId,
        type: 'image' as const,
      };

      let start: number;
      let layerId: string;
      const isReplacing = withReplace && selectedTimelineItem && selectedTimelineItemLayer;
      if (isReplacing) {
        if (timelineItem.type === selectedTimelineItem.type) {
          start = selectedTimelineItem.start;
          layerId = selectedTimelineItemLayer.id;
        }

        dispatch(
          removeTimelineItem({
            timelineItemId: selectedTimelineItemId,
            fromTimeline: true,
          }),
        );
      }

      dispatch(
        addToTimeline({
          timelineItem,
          start,
          layerId,
        }),
      );
      dispatch(
        updateTimelineItemLayer({
          timelineItemId,
        }),
      );
      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      const processingLabel = 'Stock image';
      const handleFail = (error: any) => {
        dispatch(
          setProcessingTimelineItemState({
            timelineItemId,
            label: processingLabel,
            status: 'ERROR',
            retryFunctionId,
            error: {
              details: error,
            },
          }),
        );
      };

      const retryFunctionId = uuid();
      const applyItemFunction = async () => {
        try {
          dispatch(
            setProcessingTimelineItemState({
              timelineItemId,
              label: processingLabel,
              status: 'PROCESSING',
              retryFunctionId,
            }),
          );

          const itemUrl = item.src.original;

          const response = await fetch(itemUrl);
          const blob = await response.blob();

          const fileMeta = await getImageMetadata({ blob });
          const fileName = itemUrl.split('/').pop().split('?')[0].split('#')[0];
          fileMeta.name = fileName;

          const file = new File([blob], fileName, {
            type: blob.type,
            lastModified: new Date().getTime(),
          });

          dispatch(
            createCloudAsset({
              processId,
              fileType: 'image',
              fileMeta,
              file,
              sourceMeta: {
                keyword: keywordRef.current,
              },
              originalSrc: 'pexels',
              originalData: item,
              onCacheReady: ({ cloudAsset }) => {
                dispatch(
                  removeUndoRedoById({
                    id: undoId,
                  }),
                );

                dispatch(
                  setProcessingTimelineItemState({
                    timelineItemId,
                    label: processingLabel,
                    status: 'UPLOADING',
                  }),
                );

                delete retryFunctions[retryFunctionId];

                const isOnTimeline = getIsItemOnTimeline(timelineItemId);

                if (!isOnTimeline) {
                  return;
                }

                const canvasSize = getItemCanvasSize({
                  width: fileMeta.width,
                  height: fileMeta.height,
                  resolution,
                  scaleUp: true,
                });

                dispatch(
                  updateTimelineItem({
                    id: timelineItemId,
                    cloudAssetId: cloudAsset.id,
                    x: 0,
                    y: 0,
                    width: canvasSize.width,
                    height: canvasSize.height,
                  }),
                );

                dispatch(updateCanvas({}));
                dispatch(
                  addUndo({
                    id: undoId,
                  }),
                );
                dispatch(saveVideo({}));
              },
              onCreate: () => {
                dispatch(
                  setProcessingTimelineItemState({
                    timelineItemId,
                    label: processingLabel,
                    status: 'DONE',
                  }),
                );
                setTimeout(() => {
                  dispatch(
                    deleteProcessingTimelineItemState({
                      timelineItemId,
                    }),
                  );
                }, 5000);
              },
              onFail: handleFail,
            }),
          );
        } catch (error) {
          handleFail(error);
        }
      };
      retryFunctions[retryFunctionId] = applyItemFunction;
      applyItemFunction();
    },
    [
      dispatch,
      resolution,
      selectedItem,
      selectedTimelineItem,
      selectedTimelineItemId,
      selectedTimelineItemLayer,
      setIsPreviewPanelVisible,
    ],
  );

  const handleReplaceItem = useCallback(() => {
    handleUseItem({ withReplace: true });
  }, [handleUseItem]);

  const handleItemSelect = useCallback(
    (item) => {
      setIsPreviewPanelVisible(true);
      setSelectedItem(item);
    },
    [setIsPreviewPanelVisible],
  );

  const handleItemUnselect = useCallback(() => {
    setIsPreviewPanelVisible(false);
    setSelectedItem(null);
  }, [setIsPreviewPanelVisible]);

  const renderItem = useCallback(
    ({ item }) => {
      return (
        <ImageSearchItem url={item.src.medium || item.src.original || null} item={item} onSelect={handleItemSelect} />
      );
    },
    [handleItemSelect],
  );

  const renderFilterControls = useCallback(
    ({ query }: { query: string }) => {
      return (
        <>
          <FileTypeSelector fileType={fileType} fileTypes={STOCK_FILE_TYPES} />
          <StockFilterControls query={query} orientation={orientation} setOrientation={setOrientation} />
        </>
      );
    },
    [fileType, orientation, setOrientation],
  );

  useEffect(() => {
    return () => {
      setIsPreviewPanelVisible(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Search
        results={stockImageResults.data}
        isLoading={stockImageResults.isLoading}
        isError={!!stockImageResults.error}
        hasMore={!!stockImageResults.next}
        withSearchInput
        layout='masonry'
        renderFilterControls={renderFilterControls}
        onSearch={handleSearch}
        renderItem={renderItem}
        getItemKey={getItemKey}
      />
      {selectedItem &&
        createPortal(
          <PreviewImage
            url={selectedItem.src.original}
            onAdd={handleUseItem}
            onReplace={replacingSelectedTimelineItem ? handleReplaceItem : undefined}
            onClose={handleItemUnselect}
          />,
          previewPanelRef.current,
        )}
    </>
  );
};
export default StockImages;
