import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { createBiteSelector, createdFromSelector } from '../../../store/createBite/createBite.selectors';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { biteCreatedHelpersConfigSelector } from '../../../store/appActivity/appActivity.selectors';
import { EIntroMediaProcessingStatus } from '../../../store/createBite/createBite.types';
import { getBiteId } from '../../../utils/formatDataFromServer';
import { calcFontSize, calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import { setBiteCover, setBiteCoverFromUrl, updateBiteSettings } from '../../../store/createBite/createBites.actions';
import BgMagicIcon from '../../../assets/icons/bg-magic-icon.svg';
import PointsIcon from '../../../assets/icons/creationFlow/buttons-rounded-icon.svg';
import { KeyboardAwareView } from '../../../components/shared';
import MediaModal from '../common/MediaModal';
import useMedia, { formatFileTypesForWeb } from '../../../hooks/useMedia';
import BiteSettingsModal from '../common/BiteSettingsModal';
import { updateBiteData } from '../../../store/api/bites-api/calls/bite.calls';
import uploadCover from '../../../utils/uploadImage';
import { biteSelector } from '../../../store/bite/bite.selectors';
import Routes from '../../../navigation/routes';
import { setHomeActiveTab } from '../../../store/homeScreen/homeScreen.slice';
import { Tabs } from '../../../store/homeScreen/homeScreen.types';
import { removeFromDrafts } from '../../../store/drafts/drafts.slice';
import ShareSection from '../common/ShareSection';
import CreationAnimationScreen from '../../../components/shared/CreationAnimationScreen';
import HomeButton from '../../../components/shared/HomeButton';
import BiteCoverImage from '../../../components/shared/BiteCoverImage';
import Header from '../../../components/Header';
import ShadowedContainer from '../../../components/ShadowedContainer';
import NameSuggestionComponent from './NameSuggestionComponent';
import { loadNextPage } from '../../../store/feed/feed.slice';
import { logError, trackEvent } from '../../../store/appActivity/appActivity.slice';
import Constants from '../../Shared/constants';
import { triggerCreatedBiteTransactionalCommunication, setBites } from '../../../store/bite/bite.actions';
import HelpersPanel from '../../Shared/HelpersPanel';
import Button from '../../../components/shared/buttons/Button/Button';
import { useTheme } from 'styled-components/native';
import openPreviewBite from '../../../utils/bite/openPreviewBite';
import Toast from 'react-native-toast-message';
import { EToastTypes } from '../../../utils/constants/toastConfig';
import { biteCreatedcontinueBtnDataSet } from './biteCreated.constants';
import uploadImageByUrl from '../../../utils/uploadImageByUrl';
import { useIsMounted } from '../../../hooks/useIsMounted';
import BiteCreatedElementsPanelHeader from '../../../components/shared/ElementsPanel/common/BiteCreatedElementsPanelHeader';
import useElementsPanelTitle from '../../../components/shared/ElementsPanel/hooks/useElementsPanelTitle';
import { useIsFocused } from '@react-navigation/native';
import { openProject } from '../../../store/editAiGeneration/editAiGeneration.slice';

const buttonStyle = {
  height: calcHeight(50),
  width: calcWidth(148),
};

const BiteCreated = ({ navigation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();
  const isFocused = useIsFocused();
  const isMountedRef = useIsMounted();

  const createdFrom = useSelector(createdFromSelector);
  const { selectedBite } = useSelector(biteSelector);
  const bite = useSelector(createBiteSelector);
  const org = useSelector(activeOrganizationSelector);
  const { biteName, linked_cover_url, coverKeywordSuggestion } = useSelector(createBiteSelector);
  const elementsPanelConfig = useSelector(biteCreatedHelpersConfigSelector);
  const introMediaProcessingStatus = bite.introMediaProcessingStatus;

  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false);
  const [isMediaModalVisible, setMediaModalVisible] = useState(false);

  const biteId = getBiteId(selectedBite);

  const originalVideoIsProcessing = introMediaProcessingStatus.original === EIntroMediaProcessingStatus.PROCESSING;

  const [isLoading, setLoading] = useState(true);
  const [isImageLoading, setImageLoading] = useState(false);

  const isIntroMediaProcessing = bite.introMediaProcessingStatus.s3 === EIntroMediaProcessingStatus.PROCESSING;

  useEffect(() => {
    if (isIntroMediaProcessing) {
      return;
    }
    setLoading(false);
  }, [isIntroMediaProcessing]);

  useEffect(() => {
    dispatch(
      trackEvent({
        event: 'pageview',
        props: {
          page_title: isLoading ? `${Routes.CreateBiteStack.FinalScreen}_loading` : Routes.CreateBiteStack.FinalScreen,
        },
      }),
    );
  }, [dispatch, isLoading]);

  useEffect(() => {
    if (isFocused && !isLoading && selectedBite) {
      dispatch(triggerCreatedBiteTransactionalCommunication({ biteId: selectedBite.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isFocused, isLoading]);

  const openMediaModal = useCallback(() => {
    setMediaModalVisible(true);
  }, []);

  const closeMediaModal = useCallback(() => {
    setMediaModalVisible(false);
  }, []);

  const openSettingsModal = useCallback(() => setSettingsModalVisible(true), []);

  const closeSettingsModal = useCallback(() => setSettingsModalVisible(false), []);

  const onMediaSelected = useCallback(
    async (uri, type, editedImageUri) => {
      try {
        setImageLoading(true);
        closeMediaModal();
        const {
          data: { id, image },
        } = await uploadCover(
          isWeb
            ? {
                file: uri,
                path: 'upload_cover',
              }
            : {
                uri: editedImageUri || uri,
                type,
                path: 'upload_cover',
              },
        );

        await updateBiteData(selectedBite?.id, {
          cover: id,
          linked_cover_url: image,
        });
        dispatch(setBiteCover(id, image));
        dispatch(
          setBites([
            {
              id: selectedBite?.id,
              cover_url: image,
              linked_cover_url: image,
            },
          ]),
        );
        setImageLoading(false);
      } catch (error) {
        setImageLoading(false);

        dispatch(
          logError({
            event: 'BiteCreated.onMediaSelected: error',
            error,
          }),
        );

        Toast.show({
          type: EToastTypes.networkError,
          topOffset: 0,
        });
      }
    },
    [closeMediaModal, selectedBite?.id, dispatch],
  );

  const { launchImageLibrary, launchImageCamera, dropZoneUploadingForWeb } = useMedia({
    fileTypesForWeb: formatFileTypesForWeb({ image: true }),
    onMediaSelectionCB: onMediaSelected,
  });

  const previewOnEdit = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'edit',
        props: { is_draft: false, bite_id: biteId },
      }),
    );

    navigation.replace(Routes.EditBiteStack.StackName, {
      screen: Routes.EditBiteStack.EditMain,
      params: { biteId },
    });
    closeSettingsModal();
  }, [biteId, dispatch, navigation, closeSettingsModal]);

  const openPreview = useCallback(() => {
    if (originalVideoIsProcessing) {
      return;
    }
    dispatch(
      trackEvent({
        event: 'preview_bite',
        props: { bite_id: selectedBite?.id },
      }),
    );
    openPreviewBite({
      biteId: selectedBite?.id,
    });
  }, [originalVideoIsProcessing, dispatch, selectedBite?.id]);

  const renderLeftComponent = useCallback(
    (disabled) => {
      return (
        <Button
          isShadowed
          onPress={openPreview}
          disabled={disabled}
          text={t('common.Preview')}
          style={buttonStyle}
          fill={theme.colors.white}
          border={theme.colors.primaryBlue}
          textColor={theme.colors.primaryBlue}
        />
      );
    },
    [openPreview, t, theme.colors.primaryBlue, theme.colors.white],
  );

  const skipHandler = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'back_to_my_feed',
        props: { bite_id: selectedBite?.id },
      }),
    );

    if (createdFrom?.generationId) {
      dispatch(openProject({ generationId: createdFrom.generationId }));
      return;
    }

    dispatch(setHomeActiveTab(Tabs.FEED));
    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [createdFrom?.generationId, dispatch, navigation, selectedBite?.id]);

  useEffect(() => {
    dispatch(removeFromDrafts(selectedBite?.id));
    return () => {
      dispatch(loadNextPage({ withBaseFiltersAndSorting: true }));
    };
  }, [dispatch, org.id, selectedBite?.id]);

  const handleSearchOnlineSelect = useCallback(
    async (imageUrl: string) => {
      try {
        setImageLoading(true);
        const media = await uploadImageByUrl({ url: imageUrl });

        await updateBiteData(selectedBite?.id, {
          cover: null,
          linked_cover_url: media.image_url,
        });

        dispatch(setBiteCoverFromUrl(media.image_url));
        dispatch(
          setBites([
            {
              id: selectedBite?.id,
              cover_url: media.image_url,
              linked_cover_url: media.image_url,
            },
          ]),
        );

        if (isMountedRef.current) {
          setImageLoading(false);
        }
      } catch (error) {
        setImageLoading(false);

        dispatch(
          logError({
            event: 'BiteCreated.handleSearchOnlineSelect: error',
            error,
          }),
        );

        Toast.show({
          type: EToastTypes.networkError,
          topOffset: 0,
        });
      }
    },
    [selectedBite?.id, dispatch, isMountedRef],
  );

  const applySettings = useCallback(
    (settings) => {
      dispatch(updateBiteSettings(settings));
      updateBiteData(selectedBite?.id, settings);
      closeSettingsModal();
    },
    [closeSettingsModal, selectedBite?.id, dispatch],
  );

  const imageSource = useMemo(() => {
    return {
      uri: linked_cover_url || null,
    };
  }, [linked_cover_url]);

  const renderHeaderTitle = useCallback(() => <S.Title>{t('biteCreated.title')}</S.Title>, [t]);

  const renderHeaderLeft = useCallback(() => <HomeButton />, []);

  const renderHeaderRight = useCallback(
    () => (
      <ShadowedContainer distance={25}>
        <S.PointsButton onPress={openSettingsModal}>
          <PointsIcon />
        </S.PointsButton>
      </ShadowedContainer>
    ),
    [openSettingsModal],
  );

  const renderSettingsLeftButton = useCallback(() => {
    return (
      <TouchableOpacity onPress={previewOnEdit}>
        <S.ButtonText>{t('biteCreated.settings.edit')}</S.ButtonText>
      </TouchableOpacity>
    );
  }, [previewOnEdit, t]);

  const { title: subtitle } = useElementsPanelTitle({ config: elementsPanelConfig });
  const renderCustomHeader = useCallback(
    ({ isOpen }) => (
      <BiteCreatedElementsPanelHeader title={t(elementsPanelConfig.title)} subtitle={subtitle} isOpen={isOpen} />
    ),
    [elementsPanelConfig.title, subtitle, t],
  );

  if (isLoading) {
    return <CreationAnimationScreen loadingText={t('biteCreated.animationScreenText')} />;
  }

  return (
    <>
      <S.Container>
        <View>
          <BgMagicIcon style={S.BgMagicIconStyles} />
          <Header headerLeft={renderHeaderLeft} headerRight={renderHeaderRight} title={renderHeaderTitle} />
          <NameSuggestionComponent />
          <S.CoverImageWrapper>
            <BiteCoverImage
              originalVideoIsProcessing={originalVideoIsProcessing}
              source={imageSource}
              onEditPress={openMediaModal}
              bite={selectedBite}
              isLoading={isImageLoading}
            />
          </S.CoverImageWrapper>

          {selectedBite && (
            <ShareSection
              disabled={originalVideoIsProcessing}
              subject={biteName}
              shareData={selectedBite}
              from={Constants.CreationFlows.BiteCreation}
              leftButtonComponent={renderLeftComponent}
            />
          )}

          <S.SkipButton
            // @ts-ignore
            dataSet={biteCreatedcontinueBtnDataSet}
            onPress={skipHandler}
          >
            {createdFrom?.generationId ? (
              <S.NotifyLaterText>Back To Project</S.NotifyLaterText>
            ) : originalVideoIsProcessing ? (
              <S.NotifyLaterText>{t('enhancements.notifyWhenBiteReady')}</S.NotifyLaterText>
            ) : (
              <S.SkipText>{t('biteCreated.backToMyFeed')}</S.SkipText>
            )}
          </S.SkipButton>
        </View>
      </S.Container>
      {/*<MediaProcessingBanner containerStyle={S.MediaProcessingBannerContainerStyle} />*/}
      <MediaModal
        isVisible={isMediaModalVisible}
        onClose={closeMediaModal}
        onSelectGallery={launchImageLibrary}
        onSelectCamera={launchImageCamera}
        onSelectStockImage={handleSearchOnlineSelect}
        from='cover_photo'
        defaultSearchValue={coverKeywordSuggestion ? coverKeywordSuggestion : null}
      />
      <BiteSettingsModal
        bite={selectedBite}
        renderLeftButton={renderSettingsLeftButton}
        isVisible={isSettingsModalVisible}
        onClose={closeSettingsModal}
        isPrivateDefault={bite.biteSettings.is_private}
        isAllowedDiscussionDefault={bite.biteSettings.discussion_enabled}
        isRequiredCorrectAnswerDefault={bite.biteSettings.require_correct_answer}
        isSkipEnabledDefault={bite.biteSettings.skip_able}
        onDone={applySettings}
      />
      {dropZoneUploadingForWeb}

      <HelpersPanel config={elementsPanelConfig} renderCustomHeader={renderCustomHeader} />
    </>
  );
};

const S = {
  EmptyView: styled.View`
    height: ${calcHeight(28)}px;
  `,
  Header: styled.View`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: ${calcHeight(isWeb ? -12 : 47)}px ${calcWidth(17)}px ${calcHeight(20)}px ${calcWidth(24)}px;
  `,
  Title: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s18};
    font-family: ${({ theme }) => theme.fontFamilies.Arimo};
    font-weight: normal;
    max-width: ${deviceWidth - calcWidth(60) * 2}px;
  `,
  Container: styled(isWeb ? KeyboardAwareView : View)`
    flex: 1;
  `,
  Text: styled.Text`
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    color: ${({ theme }) => theme.colors.white};
    font-size: ${calcFontSize(20)}px;
    font-family: ${({ theme }) => theme.fontFamilies.GilroyMedium};
    text-align: center;
  `,
  ImageWrap: styled.View<{ isProcessing?: boolean }>`
    justify-content: flex-end;
    align-items: center;
    align-self: center;
    overflow: hidden;
    width: ${calcWidth(248)}px;
    height: ${calcHeight(361)}px;
    ${isWeb ? 'width: 500px;' : ''}
    border-radius: 25px;
    ${({ theme, isProcessing }) => (isProcessing ? `background-color: ${theme.colors.transparentBlack}` : '')};
  `,
  Image: styled.Image<{ isProcessing?: boolean }>`
    position: absolute;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 100%;
    height: ${calcHeight(361)}px;
    opacity: ${({ isProcessing }) => (isProcessing ? 0.5 : 1)};
  `,
  BgMagicIcon: styled(BgMagicIcon)`
    position: absolute;
  `,
  PointsButton: styled.TouchableOpacity``,
  SkipButton: styled.TouchableOpacity`
    align-self: center;
    margin-top: ${calcHeight(23)}px;
  `,
  SkipText: styled.Text`
    text-align: center;
    color: ${({ theme }) => theme.colors.gray11};
  `,
  NotifyLaterText: styled.Text`
    text-align: center;
    margin: 10px;
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  BgMagicIconStyles: {
    position: 'absolute',
    alignSelf: 'center',
    height: isWeb ? calcHeight(846) : calcHeight(445),
    width: calcWidth(deviceWidth),
    opacity: 0.4,
  } as StyleProp<ViewStyle>,
  CoverImageWrapper: styled.View`
    margin-bottom: ${calcHeight(30)}px;
  `,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,

  MediaProcessingBannerContainerStyle: isWeb ? { top: calcHeight(100) } : { bottom: calcHeight(90) },
};

export default BiteCreated;
