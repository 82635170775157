import { useSelector } from 'react-redux';
import { biteInitialAttributesMapByIdSelector } from '../../../store/attributes/biteAttributes/biteAttributes.selector';
import { useMemo } from 'react';
import { playlistInitialAttributesMapByIdSelector } from '../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { contentTypeSelector } from '../../../store/analytics/analytics.selector';
import { EAnalyticsContentType } from '../../../store/analytics/analytics.types';
import { AnalyticsAttributeMapItem } from '../Analytics.types';

const useHasOrganizationAttributes = () => {
  const contentType = useSelector(contentTypeSelector);
  const biteInitialAttributesMap = useSelector(biteInitialAttributesMapByIdSelector);
  const playlistInitialAttributesMap = useSelector(playlistInitialAttributesMapByIdSelector);

  const initialAttributesMap = useMemo(() => {
    switch (contentType) {
      case EAnalyticsContentType.BITE:
        return biteInitialAttributesMap;
      case EAnalyticsContentType.PLAYLIST:
        return playlistInitialAttributesMap;
      case EAnalyticsContentType.QUIZ:
        return playlistInitialAttributesMap;
    }
  }, [contentType, biteInitialAttributesMap, playlistInitialAttributesMap]);

  const hasOrganizationAttributes = useMemo(
    () =>
      Object.values(initialAttributesMap).some((attribute: AnalyticsAttributeMapItem) => attribute.data.values.length),
    [initialAttributesMap],
  );

  return {
    hasOrganizationAttributes,
  };
};

export default useHasOrganizationAttributes;
