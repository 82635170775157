import React, { memo, useCallback, useMemo } from 'react';
import Header from '../../../components/Header';
import HomeButton from '../../../components/shared/HomeButton';
import Routes from '../../../navigation/routes';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { contentIdSelector, contentTypeSelector } from '../../../store/analytics/analytics.selector';
import { biteItemSelector } from '../../../store/bite/bite.selectors';
import dayjs from 'dayjs';
import styled from 'styled-components/native';
import { useTranslation } from 'react-i18next';
import { log } from '../../../store/appActivity/appActivity.slice';
import { StackNavigationProp } from '@react-navigation/stack';
import ContentSettings from '../../stats/bite/BiteSettings';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import ClonePlaylistButton from '../../Shared/ClonePlaylistButton';
import { calcHeight, calcWidth } from '../../../utils/dimensions';
import { EAnalyticsContentType } from '../../../store/analytics/analytics.types';

const AnalyticsScreenHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigation<StackNavigationProp<any>>();

  const contentId = useSelector(contentIdSelector);
  const bite = useSelector(biteItemSelector(contentId));
  const playlist = useSelector(playlistItemSelector(contentId));
  const contentType = useSelector(contentTypeSelector);

  const activeOrganization = useSelector(activeOrganizationSelector);

  const contentData = useMemo(() => {
    switch (contentType) {
      case EAnalyticsContentType.BITE:
        return {
          name: bite.owner_name,
          created_at: bite.created_at,
        };
      case EAnalyticsContentType.PLAYLIST:
        return {
          name: playlist.creator_name,
          created_at: playlist.created_at,
        };
      case EAnalyticsContentType.QUIZ:
        return {
          name: playlist.creator_name,
          created_at: playlist.created_at,
        };
      default:
        return {
          name: null,
          created_at: null,
        };
    }
  }, [bite, contentType, playlist]);

  const date = contentData.created_at ? dayjs(contentData.created_at).format('DD MMMM, YYYY') : null;

  const goBackHome = useCallback(() => {
    dispatch(log({ event: 'AnalyticsScreenHeader.goBackHome', data: { contentId } }));
    navigation.navigate(Routes.HomeStack.StackName, { screen: Routes.HomeStack.Home });
  }, [contentId, dispatch, navigation]);

  const renderHeaderLeft = useCallback(() => {
    return <HomeButton onPress={goBackHome} />;
  }, [goBackHome]);

  const renderHeaderRight = useCallback(() => {
    if (playlist && activeOrganization.is_creator) {
      return <ClonePlaylistButton playlistId={playlist.id} />;
    }

    if (bite) {
      return <ContentSettings contentId={contentId} />;
    }

    return <S.EmptyView />;
  }, [activeOrganization.is_creator, bite, contentId, playlist]);

  const renderHeaderTitle = useCallback(
    () => (
      <S.Container>
        <S.CreatorsNameContainer>
          <S.CreatorsName>{`${t('biteScreen.createdBy')} ${contentData.name},`}</S.CreatorsName>
        </S.CreatorsNameContainer>
        {!!date && (
          <S.DateContainer>
            <S.Date>{date}</S.Date>
          </S.DateContainer>
        )}
      </S.Container>
    ),
    [contentData.name, date, t],
  );

  return <Header headerRight={renderHeaderRight} headerLeft={renderHeaderLeft} title={renderHeaderTitle} />;
};
const S = {
  Container: styled.View`
    align-items: center;
    justify-content: space-between;
  `,
  CreatorsNameContainer: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
  `,
  CreatorsName: styled.Text``,
  Date: styled.Text`
    color: ${({ theme }) => theme.colors.gray19};
  `,
  DateContainer: styled.View``,

  EmptyView: styled.View`
    height: ${calcHeight(40)}px;
    width: ${calcWidth(40)}px;
  `,
};
export default memo(AnalyticsScreenHeader);
