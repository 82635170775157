import Routes from '../../navigation/routes';
import { navigationRef } from '../../navigation/RootNavigation';
import store from '../../store';
import { prepareAnalytics } from '../../store/analytics/analytics.slice';
import { log } from '../../store/appActivity/appActivity.slice';
import {
  getPlaylistDistributions,
  getPlaylistQuestions,
  getPlaylistStatsOverview,
  setSortBy as setSortByPlaylistStats,
} from '../../store/attributes/playlistStats/playlistStats.slice';
import { getPlaylistOrganizationAttributes } from '../../store/attributes/playlistAttributes/playlistAttributes.slice';
import { EAnalyticsContentType } from '../../store/analytics/analytics.types';
import { ESortByType } from '../../store/attributes/playlistAttributes/playlistAttributes.types';
import { ESortingDirection } from '../../types/anayltics';

const openQuizStats = ({ quizId }: { quizId: number }) => {
  store.dispatch(log({ event: 'openQuizStats', data: { quizId } }));

  store.dispatch(prepareAnalytics({ contentId: quizId, contentType: EAnalyticsContentType.QUIZ }));
  store.dispatch(getPlaylistStatsOverview());
  store.dispatch(getPlaylistQuestions());
  store.dispatch(setSortByPlaylistStats({ sortBy: ESortByType.SUCCESS, sortDirection: ESortingDirection.DESC }));

  const orgId = store.getState().playlist.playlistsMap[quizId].organization;
  store.dispatch(getPlaylistOrganizationAttributes({ organizationId: orgId }));
  store.dispatch(getPlaylistDistributions());

  navigationRef.current.navigate(Routes.AnalyticsStack.StackName, {
    screen: Routes.AnalyticsStack.PlaylistOverview,
  });
};

export default openQuizStats;
