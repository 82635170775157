import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import Button from '../../../components/shared/buttons/Button/Button';
import { Voice, VOICES_LIST } from '../../../utils/voices.constants';
import LogoIcon from '../../../assets/icons/logo.svg';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { PreviewContent, PreviewControls } from './preview/Preview';
import SidebarTitle from './SidebarTitle';
import { v4 as uuid } from 'uuid';
import { createCloudAssetCache, processCloudAsset, setCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeTimelineItem,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineItemWithCloudAssetDuration,
} from '../../../store/videoEditor/videoEditor.slice';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import SelectVoices from './SelectVoices';
import { SidebarProps } from './LeftSidebar';
import {
  selectedTimelineItemIdSelector,
  selectedTimelineItemLayerSelector,
  timelineItemSeletor,
} from '../../../store/videoEditor/videoEditor.selectors';
import { useAsyncAction } from '../../../hooks/useAsyncAction';

const MAX_TEXT_LENGTH = 1800;

type Props = SidebarProps & {
  initialText?: string;
  initialVoiceId?: string;
  initialCloudAsset?: ICloudAsset;
  isReplace?: boolean;
  onAdd?: (props: { timelineItemId: string }) => void;
};
const GenerateImageSidebar = ({
  initialText = 'Astronaut riding a horse',
  initialVoiceId,
  initialCloudAsset = null,
  setIsPreviewPanelVisible,
  setIsAdditionalPanelVisible,
  isAdditionalPanelVisible,
  isPreviewPanelVisible,
  previewPanelRef,
  additionalPanelRef,
  // isReplace,
  onAdd,
}: Props) => {
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const org = useSelector(activeOrganizationSelector);
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const selectedTimelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const selectedTimelineItemLayer = useSelector(selectedTimelineItemLayerSelector);

  const [text, setText] = useState(initialText);
  const [generatedText, setGeneratedText] = useState(initialText);

  const initialVoice = useMemo(
    () => VOICES_LIST.find((v) => v.id === initialVoiceId) || VOICES_LIST[0],
    [initialVoiceId],
  );
  const [voice, setVoice] = useState(initialVoice);
  const [previewCloudAsset, setPreviewCloudAsset] = useState<ICloudAsset>(initialCloudAsset);

  const [isSelectVoiceOpen, setIsSelectVoiceOpen] = useState(false);

  const handleGenerate = useCallback(async () => {
    if (!text || !voice) {
      return;
    }

    if (previewCloudAsset && text === generatedText) {
      setIsPreviewPanelVisible(true);
      return;
    }

    setGeneratedText(text);
    setPreviewCloudAsset(null);
    setIsPreviewPanelVisible(true);

    const response = await fetch('https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-dev', {
      headers: {
        Authorization: 'Bearer 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',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({ inputs: text }),
    });
    const result = await response.blob();
    return result;

    // setPreviewCloudAsset(data.cloudAsset);
  }, [generatedText, previewCloudAsset, setIsPreviewPanelVisible, text, voice]);

  const action = useAsyncAction(handleGenerate);

  const handleInput = useCallback((e) => {
    if (e.target.value.length > MAX_TEXT_LENGTH) {
      return;
    }
    setText(e.target.value);
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleUseItem = useCallback(
    ({ withReplace }: { withReplace?: boolean } = {}) => {
      dispatch(setCloudAsset({ cloudAsset: previewCloudAsset }));

      let start: number;
      let layerId: string;
      if (withReplace && selectedTimelineItem && selectedTimelineItemLayer) {
        start = selectedTimelineItem.start;
        layerId = selectedTimelineItemLayer.id;
        dispatch(
          removeTimelineItem({
            timelineItemId: selectedTimelineItemId,
            fromTimeline: true,
          }),
        );
      }

      const timelineItemId = uuid();
      const timelineItem: Omit<ITimelineItem, 'start' | 'end'> = {
        id: timelineItemId,
        type: previewCloudAsset.fileType as ITimelineItem['type'],
        cloudAssetId: previewCloudAsset.id,
        generatedMeta: {
          generatedFrom: 'text-to-speech',
          text,
          provider: 'elevenlabs',
          voiceId: voice.id,
        },
      };

      dispatch(
        addToTimeline({
          timelineItem,
          duration:
            previewCloudAsset.fileType === 'video' || previewCloudAsset.fileType === 'audio'
              ? previewCloudAsset.fileMeta.duration
              : null,
          start,
          layerId,
        }),
      );

      dispatch(
        updateTimelineItemLayer({
          timelineItemId,
        }),
      );

      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      setIsPreviewPanelVisible(false);
      setPreviewCloudAsset(null);

      setText('');
      setGeneratedText('');

      onAdd?.({ timelineItemId });

      const retryFunctionId = uuid();
      const processingLabel = `Text to speech: ${text}`;
      const applyItemFunction = async () => {
        dispatch(
          setProcessingTimelineItemState({
            timelineItemId,
            label: processingLabel,
            status: 'PROCESSING',
            retryFunctionId,
          }),
        );

        dispatch(
          createCloudAssetCache({
            cloudAsset: previewCloudAsset,
            timelineItems: [timelineItem],
            onCacheReady: () => {
              dispatch(
                removeUndoRedoById({
                  id: undoId,
                }),
              );
              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId,
                  label: processingLabel,
                  status: 'DONE',
                }),
              );

              setTimeout(() => {
                dispatch(
                  deleteProcessingTimelineItemState({
                    timelineItemId,
                  }),
                );
              }, 5000);

              delete retryFunctions[retryFunctionId];
              const isOnTimeline = getIsItemOnTimeline(timelineItemId);

              if (!isOnTimeline) {
                return;
              }

              dispatch(updateTimelineItem(timelineItem));

              dispatch(
                updateTimelineItemWithCloudAssetDuration({
                  timelineItemId,
                }),
              );

              dispatch(updateCanvas({}));

              dispatch(
                addUndo({
                  id: undoId,
                }),
              );

              dispatch(saveVideo({}));
            },
            onFail: (error: any) => {
              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId,
                  label: processingLabel,
                  status: 'ERROR',
                  error: {
                    details: error,
                  },
                  retryFunctionId,
                }),
              );
            },
          }),
        );

        dispatch(processCloudAsset({ cloudAsset: previewCloudAsset }));
      };

      retryFunctions[retryFunctionId] = applyItemFunction;
      applyItemFunction();
    },
    [
      dispatch,
      onAdd,
      previewCloudAsset,
      selectedTimelineItem,
      selectedTimelineItemId,
      selectedTimelineItemLayer,
      setIsPreviewPanelVisible,
      text,
      voice.id,
    ],
  );

  // const handleReplaceItem = useCallback(() => {
  //   handleUseItem({ withReplace: true });
  // }, [handleUseItem]);

  const handleClosePreview = useCallback(() => {
    setIsPreviewPanelVisible(false);
  }, [setIsPreviewPanelVisible]);

  const handleSelectVoice = useCallback(
    (newVoice: Voice) => {
      setVoice(newVoice);
      setIsAdditionalPanelVisible(false);
    },
    [setIsAdditionalPanelVisible],
  );

  useEffect(() => {
    if (!isAdditionalPanelVisible) {
      setIsSelectVoiceOpen(false);
    }
  }, [isAdditionalPanelVisible]);

  return (
    <>
      <S.Title>Generate image</S.Title>
      <S.Description>Generate an image with a prompt</S.Description>
      <S.TextareaContainer>
        <S.Textarea placeholder='Enter text here...' value={text} onInput={handleInput} />
      </S.TextareaContainer>
      <S.Controls>
        <Button
          text={previewCloudAsset && text === generatedText ? 'Preview' : 'Generate'}
          textColor={defaultTheme.colors.white}
          border={defaultTheme.colors.primaryBlue}
          fill={defaultTheme.colors.primaryBlue}
          style={buttonStyle}
          isLoading={action.isLoading}
          disabled={!text || !voice || (isPreviewPanelVisible && text === generatedText)}
          onPress={handleGenerate}
        />
      </S.Controls>
      {action.isLoading &&
        previewPanelRef.current &&
        createPortal(
          <>
            <SidebarTitle title='Generated image' bold={false} />
            <PreviewContent>
              <LogoIcon />
              <div>Generating image...</div>
              <div>Probably we should buy another plan to reduce latency</div>
            </PreviewContent>
            <PreviewControls onClose={handleClosePreview} />
          </>,
          previewPanelRef.current,
        )}
      {action.error &&
        previewPanelRef.current &&
        createPortal(
          <>
            <SidebarTitle title='Generated image' bold={false} />
            <PreviewContent>
              <div>Failed to generate audio</div>
              <div>Please try again</div>
            </PreviewContent>
            <PreviewControls onClose={handleClosePreview} />
          </>,
          previewPanelRef.current,
        )}
      {action.result &&
        previewPanelRef.current &&
        createPortal(
          <>
            <SidebarTitle title='Generated image' bold={false} />
            <PreviewContent>
              <img src={action.result} />
            </PreviewContent>
            <PreviewControls onClose={handleClosePreview} />
          </>,
          previewPanelRef.current,
        )}

      {isSelectVoiceOpen &&
        additionalPanelRef.current &&
        createPortal(<SelectVoices onSelect={handleSelectVoice} />, additionalPanelRef.current)}
    </>
  );
};
export default GenerateImageSidebar;

const buttonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 45,
  width: 133,
};

const S = {
  Title: styled.div`
    padding: 16px;
    font-size: 15px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  Description: styled.div`
    margin: 5px 16px 0;
    color: #575757;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
  TextareaContainer: styled.div`
    position: relative;
    display: flex;
    flex: 1;
    margin: 17px 16px 0;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
  `,
  Textarea: styled.textarea`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 16px 16px 32px;
    color: #000;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    border-radius: 20px;
    border: 0;
    resize: none;
  `,
  TextSizeLabel: styled.div`
    position: absolute;
    bottom: 14px;
    left: 16px;
    padding: 2px 4px;
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    border-radius: 4px;
    background: white;
  `,
  VoiceContainer: styled.div<{ isSelectVoiceOpen: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin: 16px 16px 0;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    background: ${({ isSelectVoiceOpen }) => (isSelectVoiceOpen ? defaultTheme.colors.lightGray44 : '#fff')};
    cursor: pointer;

    &:hover {
      background: ${defaultTheme.colors.lightGray44};
    }
  `,
  VoiceImageContainer: styled.div`
    position: relative;
    width: 53px;
    height: 53px;
    flex-shrink: 0;
  `,
  VoiceImage: styled.img`
    width: 53px;
    height: 53px;
    border-radius: 53px;
  `,
  PlayButton: styled.div`
    position: absolute;
    top: 50%;
    left: -8px;
    width: 30px;
    height: 30px;
  `,
  VoiceDescriptionContainer: styled.div`
    flex: 1;
  `,
  VoiceName: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  `,
  VoiceDescription: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  CaretIcon: styled.div`
    display: flex;
    align-items: center;
    width: 18px;
  `,
  Controls: styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 16px;
  `,
};
