import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIsFocused } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import {
  playlistDisplayModeSelector,
  playlistInitialAttributesMapByIdSelector,
  playlistOrganizationAttributesSelector,
} from '../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { log } from '../../../store/appActivity/appActivity.slice';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { contentTypeSelector, isRefreshingSelector } from '../../../store/analytics/analytics.selector';
import {
  biteDisplayModeSelector,
  biteInitialAttributesMapByIdSelector,
  biteOrganizationAttributesSelector,
} from '../../../store/attributes/biteAttributes/biteAttributes.selector';
import { EAnalyticsContentType } from '../../../store/analytics/analytics.types';
import { AnalyticsAttributeMap, IGetAttributesPayload } from '../Analytics.types';

interface IProps {
  attributesMap: AnalyticsAttributeMap;
  onLoad: ActionCreatorWithOptionalPayload<IGetAttributesPayload, string>;
  isRefreshing?: boolean;
  manuallySelectedSectionId?: number;
}

export interface IAttributeToRender {
  id: number;
  title: string;
  data: any[];
  isLoading: boolean;
  isError: boolean;
  next: string;
  isDisplaySkeleton: boolean;
}

const useAttributesData = ({ attributesMap, onLoad, manuallySelectedSectionId }: IProps) => {
  const dispatch = useDispatch();
  const isFocused = useIsFocused();

  const isRefreshing = useSelector(isRefreshingSelector);
  const contentType = useSelector(contentTypeSelector);
  const playlistDisplayMode = useSelector(playlistDisplayModeSelector);
  const biteDisplayMode = useSelector(biteDisplayModeSelector);
  const playlistOrganizationAttributes = useSelector(playlistOrganizationAttributesSelector);
  const biteOrganizationAttributes = useSelector(biteOrganizationAttributesSelector);
  const playlistInitialAttributesMapById = useSelector(playlistInitialAttributesMapByIdSelector);
  const biteInitialAttributesMapById = useSelector(biteInitialAttributesMapByIdSelector);

  const attributesData = useMemo(() => {
    switch (contentType) {
      case EAnalyticsContentType.BITE:
        return {
          displayMode: biteDisplayMode,
          initialAttributesMapById: biteInitialAttributesMapById,
          organizationAttributes: biteOrganizationAttributes,
        };
      case EAnalyticsContentType.PLAYLIST:
        return {
          displayMode: playlistDisplayMode,
          initialAttributesMapById: playlistInitialAttributesMapById,
          organizationAttributes: playlistOrganizationAttributes,
        };
      case EAnalyticsContentType.QUIZ:
        return {
          displayMode: playlistDisplayMode,
          initialAttributesMapById: playlistInitialAttributesMapById,
          organizationAttributes: playlistOrganizationAttributes,
        };
      default:
        return {
          displayMode: null,
          initialAttributesMapById: null,
          organizationAttributes: null,
        };
    }
  }, [
    contentType,
    biteDisplayMode,
    biteInitialAttributesMapById,
    biteOrganizationAttributes,
    playlistDisplayMode,
    playlistInitialAttributesMapById,
    playlistOrganizationAttributes,
  ]);

  const lastState = useRef({
    isFocused: null,
    selectedSectionId: null,
    displayMode: null,
  });

  const isLeavingFocusedMode = lastState.current.selectedSectionId && !manuallySelectedSectionId;

  const { attributesToRender, singleAttributeId } = useMemo(() => {
    const formattedAttributes = attributesData.organizationAttributes
      .filter((attr) => !manuallySelectedSectionId || attr.id === manuallySelectedSectionId)
      .map((attr) => ({
        id: attr.id,
        title: attributesMap[attr.id].data.title,
        data: null,
        isLoading: attributesMap[attr.id].isLoading,
        isError: !!attributesMap[attr.id].error,
        next: attributesMap[attr.id].next,
      }));

    const filteredList = formattedAttributes.filter(
      (attr) =>
        (attributesMap[attr.id].isLoading && attributesMap[attr.id].data.values === null) ||
        attributesMap[attr.id].error ||
        attributesMap[attr.id].data.values?.length,
    );

    const singleId = filteredList.length === 1 && !isLeavingFocusedMode ? filteredList[0].id : null;

    const formattedAttributesWithData = formattedAttributes.map((attr) => {
      const isDisplayError = attributesMap[attr.id].error && !attributesMap[attr.id].data.values?.length;
      const isDisplaySkeleton =
        attributesMap[attr.id].isLoading && !isRefreshing && !attributesMap[attr.id].data.values?.length;

      return {
        ...attr,
        isDisplaySkeleton,
        data:
          isDisplaySkeleton || isDisplayError || !attributesMap[attr.id].data.values
            ? []
            : manuallySelectedSectionId || singleId
            ? attributesMap[attr.id].data.values
            : attributesMap[attr.id].data.values.slice(0, 6),
      };
    });

    const result: IAttributeToRender[] = singleId
      ? [formattedAttributesWithData.find(({ id }) => id === singleId)]
      : formattedAttributesWithData;

    return {
      attributesToRender: result,
      singleAttributeId: singleId,
    };
  }, [
    attributesData.organizationAttributes,
    isLeavingFocusedMode,
    manuallySelectedSectionId,
    attributesMap,
    isRefreshing,
  ]);

  const attributeIdsToLoad = useMemo(() => {
    const isNeedToLoadOnError =
      (!lastState.current.isFocused && isFocused) ||
      isLeavingFocusedMode ||
      lastState.current.displayMode !== attributesData.displayMode;

    return attributesToRender
      .map(({ id, isLoading, isError }) => {
        return !isLoading &&
          ((isError && isNeedToLoadOnError) || attributesMap[id].data.values === null) &&
          isFocused &&
          (attributesData.initialAttributesMapById[id].data.values.length === 1 || isRefreshing)
          ? id
          : null;
      })
      .filter((id) => id)
      .sort(); // sort to compare with previous state
  }, [
    attributesMap,
    attributesToRender,
    attributesData.displayMode,
    attributesData.initialAttributesMapById,
    isFocused,
    isLeavingFocusedMode,
    isRefreshing,
  ]);

  lastState.current = {
    isFocused,
    displayMode: attributesData.displayMode,
    selectedSectionId: manuallySelectedSectionId,
  };

  const handleLoad = useCallback(() => {
    dispatch(
      log({
        event: 'useAttributesData.handleLoad',
      }),
    );

    dispatch(onLoad({ attributeIds: attributeIdsToLoad, clearAnalyticsCache: false, reset: true }));
  }, [attributeIdsToLoad, dispatch, onLoad]);

  useEffect(() => {
    if (attributeIdsToLoad.length === 0) {
      return;
    }

    handleLoad();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeIdsToLoad.length]);

  return { attributesToRender, singleAttributeId };
};

export default useAttributesData;
