import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { contentIdSelector } from '../../../store/analytics/analytics.selector';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import StatsOverview from './common/StatsOverview';
import usePlaylistBitesState from '../hooks/usePlaylistBitesState';

interface IProps {
  isRefreshing: boolean;
}

const QuizStatsOverview: React.FC<IProps> = ({ isRefreshing }) => {
  const contentId = useSelector(contentIdSelector);
  const contentItem = useSelector(playlistItemSelector(contentId));
  const { isBitesLoading, isBitesError } = usePlaylistBitesState(contentItem);

  return (
    <StatsOverview
      contentItem={contentItem}
      isRefreshing={isRefreshing}
      isBitesLoading={isBitesLoading}
      isBitesError={isBitesError}
    />
  );
};

export default memo(QuizStatsOverview);
