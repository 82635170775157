export type Font = {
  label: string;
  fileName: string;
  weightLabel: string;
  weight?: number;
  italic?: boolean;
};

const weightLabels = {
  100: 'Thin',
  200: 'ExtraLight',
  300: 'Light',
  400: 'Normal',
  500: 'Medium',
  600: 'SemiBold',
  700: 'Bold',
  800: 'ExtraBold',
  900: 'Black',
};

const getVariations = ({
  label,
  getFileName,
  variations,
}: {
  label: string;
  getFileName: ({ weight, italic }: { weight: number; italic?: boolean }) => string;
  variations: { weight: number; weightLabel?: string; italic?: boolean }[];
}) => {
  return variations.flatMap(({ weight, weightLabel, italic }) => {
    const font: Font = {
      label,
      fileName: getFileName({ weight, italic }),
      weightLabel: weightLabel || weightLabels[weight],
      weight,
      italic,
    };

    if (italic !== undefined) {
      return [font];
    }

    const fileNameRegular = getFileName({ weight, italic: false });
    const fileNameItalic = getFileName({ weight, italic: true });

    return [
      {
        ...font,
        fileName: fileNameRegular,
        italic: false,
      },
      {
        ...font,
        fileName: fileNameItalic,
        italic: true,
      },
    ];
  });
};

export const fonts: Font[] = [
  {
    label: 'NotoSans',
    fileName: 'NotoSans-Black.ttf',
    weightLabel: 'Black',
    weight: 900,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-BlackItalic.ttf',
    weightLabel: 'Black',
    weight: 900,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-Bold.ttf',
    weightLabel: 'Bold',
    weight: 700,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-BoldItalic.ttf',
    weightLabel: 'Bold',
    weight: 700,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-ExtraBold.ttf',
    weightLabel: 'ExtraBold',
    weight: 800,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-ExtraBoldItalic.ttf',
    weightLabel: 'ExtraBold',
    weight: 800,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-SemiBold.ttf',
    weightLabel: 'SemiBold',
    weight: 600,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-SemiBoldItalic.ttf',
    weightLabel: 'SemiBold',
    weight: 600,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-Medium.ttf',
    weightLabel: 'Medium',
    weight: 500,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-MediumItalic.ttf',
    weightLabel: 'Medium',
    weight: 500,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-Regular.ttf',
    weightLabel: 'Normal',
    weight: 400,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-Italic.ttf',
    weightLabel: 'Normal',
    weight: 400,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-Light.ttf',
    weightLabel: 'Light',
    weight: 300,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-LightItalic.ttf',
    weightLabel: 'Light',
    weight: 300,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-ExtraLight.ttf',
    weightLabel: 'ExtraLight',
    weight: 200,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-ExtraLightItalic.ttf',
    weightLabel: 'ExtraLight',
    weight: 200,
    italic: true,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-Thin.ttf',
    weightLabel: 'Thin',
    weight: 100,
    italic: false,
  },
  {
    label: 'NotoSans',
    fileName: 'NotoSans-ThinItalic.ttf',
    weightLabel: 'Thin',
    weight: 100,
    italic: true,
  },
  // ShadowsIntoLight
  {
    label: 'ShadowsIntoLight',
    fileName: 'ShadowsIntoLight-Regular.ttf',
    weightLabel: 'Regular',
    weight: 400,
    italic: false,
  },
  // Figtree
  {
    label: 'Figtree',
    fileName: 'Figtree-Black.ttf',
    weightLabel: 'Black',
    weight: 900,
    italic: false,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-BlackItalic.ttf',
    weightLabel: 'Black',
    weight: 900,
    italic: true,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-ExtraBold.ttf',
    weightLabel: 'ExtraBold',
    weight: 800,
    italic: false,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-ExtraBoldItalic.ttf',
    weightLabel: 'ExtraBold',
    weight: 800,
    italic: true,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-Bold.ttf',
    weightLabel: 'Bold',
    weight: 700,
    italic: false,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-BoldItalic.ttf',
    weightLabel: 'Bold',
    weight: 700,
    italic: true,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-SemiBold.ttf',
    weightLabel: 'SemiBold',
    weight: 600,
    italic: false,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-SemiBoldItalic.ttf',
    weightLabel: 'SemiBold',
    weight: 600,
    italic: true,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-Medium.ttf',
    weightLabel: 'Medium',
    weight: 500,
    italic: false,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-MediumItalic.ttf',
    weightLabel: 'Medium',
    weight: 500,
    italic: true,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-Regular.ttf',
    weightLabel: 'Regular',
    weight: 400,
    italic: false,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-Italic.ttf',
    weightLabel: 'Normal',
    weight: 400,
    italic: true,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-Light.ttf',
    weightLabel: 'Light',
    weight: 300,
    italic: false,
  },
  {
    label: 'Figtree',
    fileName: 'Figtree-LightItalic.ttf',
    weightLabel: 'Light',
    weight: 300,
    italic: true,
  },
  // Courgette
  {
    label: 'Courgette',
    fileName: 'Courgette-Regular.ttf',
    weightLabel: 'Regular',
    weight: 400,
    italic: false,
  },
  // Comfortaa
  {
    label: 'Comfortaa',
    fileName: 'Comfortaa-Bold.ttf',
    weightLabel: 'Bold',
    weight: 700,
    italic: false,
  },
  {
    label: 'Comfortaa',
    fileName: 'Comfortaa-SemiBold.ttf',
    weightLabel: 'SemiBold',
    weight: 600,
    italic: false,
  },
  {
    label: 'Comfortaa',
    fileName: 'Comfortaa-Medium.ttf',
    weightLabel: 'Medium',
    weight: 500,
    italic: false,
  },
  {
    label: 'Comfortaa',
    fileName: 'Comfortaa-Regular.ttf',
    weightLabel: 'Regular',
    weight: 400,
    italic: false,
  },
  {
    label: 'Comfortaa',
    fileName: 'Comfortaa-Light.ttf',
    weightLabel: 'Light',
    weight: 300,
    italic: false,
  },
  // BebasNeue
  {
    label: 'BebasNeue',
    fileName: 'BebasNeue-Regular.ttf',
    weightLabel: 'Regular',
    weight: 400,
    italic: false,
  },
  // Arimo
  {
    label: 'Arimo',
    fileName: 'Arimo-Bold.ttf',
    weightLabel: 'Bold',
    weight: 700,
    italic: false,
  },
  {
    label: 'Arimo',
    fileName: 'Arimo-BoldItalic.ttf',
    weightLabel: 'Bold',
    weight: 700,
    italic: true,
  },
  {
    label: 'Arimo',
    fileName: 'Arimo-SemiBold.ttf',
    weightLabel: 'SemiBold',
    weight: 600,
    italic: false,
  },
  {
    label: 'Arimo',
    fileName: 'Arimo-SemiBoldItalic.ttf',
    weightLabel: 'SemiBold',
    weight: 600,
    italic: true,
  },
  {
    label: 'Arimo',
    fileName: 'Arimo-Medium.ttf',
    weightLabel: 'Medium',
    weight: 500,
    italic: false,
  },
  {
    label: 'Arimo',
    fileName: 'Arimo-MediumItalic.ttf',
    weightLabel: 'Medium',
    weight: 500,
    italic: true,
  },
  {
    label: 'Arimo',
    fileName: 'Arimo-Regular.ttf',
    weightLabel: 'Normal',
    weight: 400,
    italic: false,
  },
  {
    label: 'Arimo',
    fileName: 'Arimo-Italic.ttf',
    weightLabel: 'Normal',
    weight: 400,
    italic: true,
  },
  {
    label: 'AlfaSlabOne',
    fileName: 'AlfaSlabOne-Regular.ttf',
    weightLabel: 'Regular',
    weight: 400,
    italic: false,
  },
  ...getVariations({
    label: 'Avenir',
    getFileName: ({ weight, italic }) => `Avenir-${weight}-${italic ? 'Italic' : 'Regular'}.ttf`,
    variations: [{ weight: 300 }, { weight: 400 }, { weight: 500 }, { weight: 800 }, { weight: 900 }],
  }),
  ...getVariations({
    label: 'AvenirNext',
    getFileName: ({ weight, italic }) => `AvenirNext-${weight}-${italic ? 'Italic' : 'Regular'}.ttf`,
    variations: [
      { weight: 275, weightLabel: 'Light' },
      { weight: 400 },
      { weight: 500, italic: true },
      { weight: 600, italic: true },
      { weight: 700 },
      { weight: 900 },
    ],
  }),
  ...getVariations({
    label: 'AvenirNextCondensed',
    getFileName: ({ weight, italic }) => `AvenirNextCondensed-${weight}-${italic ? 'Italic' : 'Regular'}.ttf`,
    variations: [
      { weight: 275, weightLabel: 'Light', italic: true },
      { weight: 400 },
      { weight: 500, italic: true },
      { weight: 600 },
      { weight: 700 },
      { weight: 900, italic: true },
    ],
  }),
];

export const fontsByFileName = fonts.reduce((acc, font) => {
  acc[font.fileName] = font;
  return acc;
}, {} as Record<string, Font>);

export const fontsLoaded = {};
export const fontsLoading = {};
