import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ICloneCloudAssetCacheForTimelineItem,
  ICloudAsset,
  ICreateCloudAssetCache,
  ICreateCloudAssetPayload,
  IGenerateTalkingAvatar,
  IGetCloudAssets,
  IInitialState,
  IProcessCloudAssetMetaPayload,
  IResizeCloudAssetForResolutionPayload,
  LoadCloudAssets,
  ToggleIsBrandCloudAsset,
  ToggleIsBrandCloudAssetDone,
  ToggleIsBrandCloudAssetError,
  ToggleIsFavoriteCloudAsset,
  ToggleIsFavoriteCloudAssetDone,
  ToggleIsFavoriteCloudAssetError,
} from './cloudAssets.types';

export const initialState: IInitialState = {
  cloudAssets: {},
  temporaryCloudAssetIds: {},
  cloudAssetCacheReady: {},
  toggleIsFavoriteById: {},
  toggleIsBrandById: {},
};

const setCloudAssetHelper = (state, cloudAsset: ICloudAsset) => {
  state.cloudAssets[cloudAsset.id!] = {
    cloudAsset,
  };

  const tmp = Object.entries(state.temporaryCloudAssetIds).find(([, id]) => {
    return id === cloudAsset.id;
  });

  if (tmp) {
    state.cloudAssets[tmp[0]] = {
      cloudAsset,
    };
  }
};

const sliceName = 'cloudAssets';

const cloudAssetsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setCloudAsset: (
      state,
      {
        payload,
      }: PayloadAction<{
        cloudAsset: ICloudAsset;
      }>,
    ) => {
      const { cloudAsset } = payload;
      setCloudAssetHelper(state, cloudAsset);
    },
    setCloudAssets: (
      state,
      {
        payload,
      }: PayloadAction<{
        cloudAssets: ICloudAsset[];
      }>,
    ) => {
      payload.cloudAssets.forEach((cloudAsset) => {
        setCloudAssetHelper(state, cloudAsset);
      });
    },
    setTemporaryCloudAsset: (
      state,
      {
        payload,
      }: PayloadAction<{
        temporaryCloudAssetId: string;
        cloudAsset: ICloudAsset;
      }>,
    ) => {
      const { temporaryCloudAssetId, cloudAsset } = payload;

      state.temporaryCloudAssetIds[temporaryCloudAssetId] = cloudAsset.id;
      state.cloudAssets[temporaryCloudAssetId] = {
        cloudAsset,
      };
    },
    setCloudAssetCacheReady: (state, { payload }: PayloadAction<{ cloudAssetId: ICloudAsset['id'] }>) => {
      const n = state.cloudAssetCacheReady[payload.cloudAssetId] || 0;
      state.cloudAssetCacheReady[payload.cloudAssetId] = n + 1;
    },

    // favorite
    toggleIsFavoriteCloudAsset: (state, { payload }: PayloadAction<ToggleIsFavoriteCloudAsset>) => {
      state.toggleIsFavoriteById[payload.cloudAssetId] = {
        isLoading: true,
        error: null,
      };
    },
    toggleIsFavoriteCloudAssetDone: (state, { payload }: PayloadAction<ToggleIsFavoriteCloudAssetDone>) => {
      delete state.toggleIsFavoriteById[payload.cloudAssetId];
    },
    toggleIsFavoriteCloudAssetError: (state, { payload }: PayloadAction<ToggleIsFavoriteCloudAssetError>) => {
      state.toggleIsFavoriteById[payload.cloudAssetId] = {
        isLoading: false,
        error: null,
      };
    },
    // brand
    toggleIsBrandCloudAsset: (state, { payload }: PayloadAction<ToggleIsBrandCloudAsset>) => {
      state.toggleIsBrandById[payload.cloudAssetId] = {
        isLoading: true,
        error: null,
      };
    },
    toggleIsBrandCloudAssetDone: (state, { payload }: PayloadAction<ToggleIsBrandCloudAssetDone>) => {
      delete state.toggleIsBrandById[payload.cloudAssetId];
    },
    toggleIsBrandCloudAssetError: (state, { payload }: PayloadAction<ToggleIsBrandCloudAssetError>) => {
      state.toggleIsBrandById[payload.cloudAssetId] = {
        isLoading: false,
        error: null,
      };
    },
  },
});
export default cloudAssetsSlice.reducer;

export const {
  setCloudAsset,
  setCloudAssets,
  setTemporaryCloudAsset,
  setCloudAssetCacheReady,
  // favorite
  toggleIsFavoriteCloudAsset,
  toggleIsFavoriteCloudAssetDone,
  toggleIsFavoriteCloudAssetError,
  // brand
  toggleIsBrandCloudAsset,
  toggleIsBrandCloudAssetDone,
  toggleIsBrandCloudAssetError,
} = cloudAssetsSlice.actions;

export const createCloudAsset = createAction<ICreateCloudAssetPayload>(`${sliceName}/createCloudAsset`);
export const generateTalkingAvatar = createAction<IGenerateTalkingAvatar>(`${sliceName}/generateTalkingAvatar`);
export const createCloudAssetCache = createAction<ICreateCloudAssetCache>(`${sliceName}/createCloudAssetCache`);

export const loadVideoAssets = createAction<IGetCloudAssets>(`${sliceName}/loadVideoAssets`);

export const cloneCloudAssetCacheForTimelineItem = createAction<ICloneCloudAssetCacheForTimelineItem>(
  `${sliceName}/cloneCloudAssetCacheForTimelineItem`,
);

export const resizeCloudAssetForResolution = createAction<IResizeCloudAssetForResolutionPayload>(
  `${sliceName}/resizeCloudAssetForResolution`,
);
export const processCloudAssetMeta = createAction<IProcessCloudAssetMetaPayload>(`${sliceName}/processCloudAssetMeta`);
export const loadCloudAssets = createAction<LoadCloudAssets>(`${sliceName}/loadCloudAssets`);

export const processCloudAsset = createAction<{ cloudAsset: ICloudAsset; processId?: string }>(
  `${sliceName}/processCloudAsset`,
);
