import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectedTimelineItemIdSelector, timelineItemSeletor } from '../../../store/videoEditor/videoEditor.selectors';
import { updateTimelineItem } from '../../../store/videoEditor/videoEditor.slice';
import Title from './Title.web';
import defaultTheme from '../../../themes';
import { cloudAssetSelector } from '../../../store/cloudAssets/cloudAssets.selector';
import TextToSpeechSettings from './TextToSpeechSettings';

const AudioSettings = () => {
  const dispatch = useDispatch();

  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const timelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const cloudAsset = useSelector(cloudAssetSelector(timelineItem.cloudAssetId));

  const isTextToSpeech = cloudAsset?.originalSrc === 'elevenlabs';

  const handleVolumeInput = useCallback(
    (e) => {
      e.stopPropagation();
      dispatch(
        updateTimelineItem({
          id: selectedTimelineItemId,
          volume: parseInt(e.target.value, 10),
        }),
      );
    },
    [dispatch, selectedTimelineItemId],
  );

  return (
    <>
      <Title text={isTextToSpeech ? 'Narration' : 'Audio'} />
      <S.ControlTitle>Volume</S.ControlTitle>
      <S.ControlContainer>
        <S.RangeInput
          type='range'
          min='0'
          max='100'
          step='1'
          value={timelineItem.volume !== undefined ? timelineItem.volume : 100}
          onInput={handleVolumeInput}
        />
      </S.ControlContainer>
      <S.Spacing size={2} />
      {isTextToSpeech && <TextToSpeechSettings />}
    </>
  );
};
export default AudioSettings;

const S = {
  Separator: styled.div`
    height: 1px;
    background-color: ${defaultTheme.colors.lightGray45};
    margin: 16px 0;
  `,
  Spacing: styled.div<{ size?: number }>`
    height: ${({ size = 1 }) => size * 8}px;
  `,
  ControlTitle: styled.div`
    padding: 0 8px;
    font-size: 13px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  ControlContainer: styled.div`
    position: relative;
    padding: 0 8px;
  `,
  Textarea: styled.textarea`
    padding: 4px 8px;
    width: 100%;
    height: 80px;
    border: 1px solid ${defaultTheme.colors.gray17};
    border-radius: 8px;
    resize: vertical;
  `,
  ColorControlContainer: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    margin: 0 8px;
    padding: 2px;
    border: 1px solid ${defaultTheme.colors.gray17};
  `,
  ColorInput: styled.div<{ color: string }>`
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    border-radius: 8px;
    background-color: ${({ color }) => color || '#000000'};
  `,
  ColorInputText: styled.input<{ value: string }>`
    flex: 1;
    width: 100%;
    line-height: 100%;
    font-size: 14px;
    color: ${defaultTheme.colors.text};
    font-family: ${defaultTheme.fontFamilies.Arimo};
    border: 0;
  `,
  HiddenColorInput: styled.input`
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
  `,
  Select: styled.select`
    padding: 4px 8px;
    width: 100%;
    height: 32px;
    border: 1px solid ${defaultTheme.colors.gray17};
    border-radius: 8px;
    cursor: pointer;
  `,
  RangeInput: styled.input`
    width: 100%;
  `,
  RangeInputWithTextControl: styled.input`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  `,
  BtnGroup: styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    padding: 0 8px;
  `,
  BtnGroupLeft: styled.div<{ isSelected: boolean }>`
    padding: 8px;
    height: 36px;
    border: 1px solid #ccc;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
    cursor: pointer;
  `,
  BtnGroupCenter: styled.div<{ isSelected: boolean }>`
    padding: 8px;
    height: 36px;
    border: 1px solid #ccc;
    background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
    cursor: pointer;
  `,
  BtnGroupRight: styled.div<{ isSelected: boolean }>`
    padding: 8px;
    height: 36px;
    border: 1px solid #ccc;
    border-left: none;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: ${(props) => (props.isSelected ? '#f0f0f0' : 'transparent')};
    cursor: pointer;
  `,
  CheckboxContainer: styled.label`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
};
