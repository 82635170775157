import React, { useCallback, useMemo, useState } from 'react';
import Cloud from './Cloud';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { FileTypeSelector } from './FileTypeSelector';
import SidebarTitle from './SidebarTitle';
import { useSelector } from 'react-redux';
import {
  biteSelector,
  cloudFileTypeSelector,
  generationIdSelector,
  scopedResultsSelector,
  scopeSourceSelector,
} from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.selectors';
import { setScopedResults } from '../../../store/videoEditorLeftSidebar/videoEditorLeftSidebar.slice';
import { Sources } from './Sources/Sources';
import { videoSelector } from '../../../store/videoEditor/videoEditor.selectors';
import UploadControl from './UploadControl';
import FileUpload from './FileUpload.web';
import { generationByIdSelector } from '../../../store/aiGeneration/aiGeneration.selector';
import { SidebarProps } from './LeftSidebar';

const FILE_TYPES: ('all' | ICloudAsset['fileType'])[] = ['all', 'video', 'image', 'gif', 'audio'];

const ScopedSidebar = ({ previewPanelRef, setIsPreviewPanelVisible }: SidebarProps) => {
  const scopedResults = useSelector(scopedResultsSelector);
  const bite = useSelector(biteSelector);
  const generationId = useSelector(generationIdSelector);
  const scopeSource = useSelector(scopeSourceSelector);
  const video = useSelector(videoSelector);
  const fileType = useSelector(cloudFileTypeSelector);
  const generation = useSelector(generationByIdSelector(generationId));

  const [isUploadVisible, setIsUploadVisible] = useState(false);

  const additionalRequestFilters = useMemo(
    () =>
      scopeSource === 'CURRENT_BITE'
        ? {
            usedInVideoId: video.id,
          }
        : scopeSource === 'ANOTHER_BITE'
        ? {
            usedInVideoId: bite?.videoId,
          }
        : scopeSource === 'GENERATION'
        ? {
            extractedFromCloudAssetIds: generation.inputCloudAssetIds,
          }
        : {},
    [bite?.videoId, generation?.inputCloudAssetIds, scopeSource, video.id],
  );

  const renderUploadControls = useCallback(() => {
    return <UploadControl isVisible={isUploadVisible} setIsVisible={setIsUploadVisible} />;
  }, [isUploadVisible]);

  const renderFilterControls = useCallback(() => {
    return <FileTypeSelector fileType={fileType} fileTypes={FILE_TYPES} />;
  }, [fileType]);

  return (
    <>
      <SidebarTitle title='Libraries' renderControls={renderUploadControls} />

      {!isUploadVisible ? (
        <>
          <Sources />
          <Cloud
            cloudResults={scopedResults}
            additionalRequestFilters={additionalRequestFilters}
            renderFilterControls={renderFilterControls}
            setCloudResults={setScopedResults}
            setIsPreviewPanelVisible={setIsPreviewPanelVisible}
            previewPanelRef={previewPanelRef}
          />
        </>
      ) : (
        <FileUpload setIsUploadVisible={setIsUploadVisible} />
      )}
    </>
  );
};
export default ScopedSidebar;
