// @ts-nocheck
import BitesApi from '../index';
import { EAggregatedFields, ESharedWithFilter, ESortBy } from '../../../attributes/biteAttributes/biteAttributes.types';
import { ESortDirection } from '../../../../types/common';

interface IGetAttributesListRequest {
  orgId: number;
  attributeId?: number;
  biteIds?: number[];
  playlistIds?: number[];
  quizIds?: number[];
  attributeValueIds?: number[];
  searchAttributeValue?: string;
  aggregatedFields?: EAggregatedFields[];
  next?: string;
  pageSize?: number;
  sortBy?: ESortBy;
  sortDirection?: ESortDirection;
  answerIds?: number[];
  sharedWith?: ESharedWithFilter[];
  allowedDataOnly?: boolean;
}

interface IGetGuestsAttributeRequest {
  orgId: number;
  biteIds?: number[];
  playlistIds?: number[];
  aggregatedFields?: EAggregatedFields[];
  answerIds?: number[];
  allowedDataOnly?: boolean;
}

export const getOrganizationAttributes = async ({
  orgId,
  attributeId,
  attributeValueIds,
  searchAttributeValue,
  sharedWith,
  biteIds,
  playlistIds,
  pageSize = 10,
  next,
}: IGetAttributesListRequest) =>
  BitesApi.get(`organization/${orgId}/attribute_title/${attributeId ? attributeId + '/' : ''}`, {
    params: {
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      search_attribute_value: searchAttributeValue,
      bite_ids: biteIds?.length ? JSON.stringify(biteIds) : undefined,
      playlist_ids: playlistIds?.length ? JSON.stringify(playlistIds) : undefined,
      page_size: pageSize,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      next,
    },
  });

export const getAnalyticsAttributes = async ({
  orgId,
  aggregatedFields,
  attributeId,
  biteIds,
  attributeValueIds,
  searchAttributeValue,
  sortBy,
  sortDirection,
  sharedWith,
  next,
  answerIds,
  pageSize = 10,
  allowedDataOnly,
}: IGetAttributesListRequest) =>
  BitesApi.get(`analytics/${orgId}/attribute_title/${attributeId ? attributeId + '/' : ''}`, {
    params: {
      bite_ids: biteIds?.length ? JSON.stringify(biteIds) : undefined,
      attribute_value_ids: attributeValueIds?.length ? JSON.stringify(attributeValueIds) : undefined,
      search_attribute_value: searchAttributeValue,
      aggregated_fields: aggregatedFields?.length ? JSON.stringify(aggregatedFields) : undefined,
      answer_ids: answerIds?.length ? JSON.stringify(answerIds) : undefined,
      shared_with: sharedWith?.length ? JSON.stringify(sharedWith) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
      next,
      sort_by: sortBy,
      sort_direction: sortDirection,
      page_size: pageSize,
    },
  });

export const getGuestsAttribute = async ({
  orgId,
  aggregatedFields,
  biteIds,
  answerIds,
  allowedDataOnly,
}: IGetGuestsAttributeRequest) =>
  BitesApi.get(`analytics/${orgId}/guests/`, {
    params: {
      bite_ids: biteIds?.length ? JSON.stringify(biteIds) : undefined,
      aggregated_fields: aggregatedFields?.length ? JSON.stringify(aggregatedFields) : undefined,
      answer_ids: answerIds?.length ? JSON.stringify(answerIds) : undefined,
      allowed_data_only: allowedDataOnly || undefined,
    },
  });
