import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  biteFilterAttributesMapByIdSelector,
  biteInitialAttributesMapByIdSelector,
  biteOrganizationAttributesSelector,
} from '../../../store/attributes/biteAttributes/biteAttributes.selector';
import {
  playlistFilterAttributesMapByIdSelector,
  playlistInitialAttributesMapByIdSelector,
  playlistOrganizationAttributesSelector,
} from '../../../store/attributes/playlistAttributes/playlistAttributes.selector';
import { contentTypeSelector } from '../../../store/analytics/analytics.selector';
import { EAnalyticsContentType } from '../../../store/analytics/analytics.types';

const useHasAttributesToRender = ({ attributesMap }) => {
  const contentType = useSelector(contentTypeSelector);

  const biteOrganizationAttributes = useSelector(biteOrganizationAttributesSelector);
  const biteFilterAttributesMapById = useSelector(biteFilterAttributesMapByIdSelector);
  const biteInitialAttributesMap = useSelector(biteInitialAttributesMapByIdSelector);

  const playlistOrganizationAttributes = useSelector(playlistOrganizationAttributesSelector);
  const playlistFilterAttributesMapById = useSelector(playlistFilterAttributesMapByIdSelector);
  const playlistInitialAttributesMap = useSelector(playlistInitialAttributesMapByIdSelector);

  const data = useMemo(() => {
    switch (contentType) {
      case EAnalyticsContentType.BITE:
        return {
          organizationAttributes: biteOrganizationAttributes,
          filterAttributesMapById: biteFilterAttributesMapById,
          initialAttributesMap: biteInitialAttributesMap,
        };
      case EAnalyticsContentType.PLAYLIST:
        return {
          organizationAttributes: playlistOrganizationAttributes,
          filterAttributesMapById: playlistFilterAttributesMapById,
          initialAttributesMap: playlistInitialAttributesMap,
        };
      case EAnalyticsContentType.QUIZ:
        return {
          organizationAttributes: playlistOrganizationAttributes,
          filterAttributesMapById: playlistFilterAttributesMapById,
          initialAttributesMap: playlistInitialAttributesMap,
        };
    }
  }, [
    contentType,
    biteOrganizationAttributes,
    biteFilterAttributesMapById,
    biteInitialAttributesMap,
    playlistOrganizationAttributes,
    playlistFilterAttributesMapById,
    playlistInitialAttributesMap,
  ]);

  const hasAttributesToRender = useMemo(
    () =>
      data.organizationAttributes
        .filter((attr) => !data.filterAttributesMapById[attr.id].selectedValues.length)
        .some(
          (attr) =>
            attributesMap[attr.id].error ||
            attributesMap[attr.id].isLoading ||
            (data.initialAttributesMap[attr.id].data.values.length && attr.values === null) ||
            attr.values?.length,
        ),
    [attributesMap, data.filterAttributesMapById, data.initialAttributesMap, data.organizationAttributes],
  );

  return {
    hasAttributesToRender,
  };
};

export default useHasAttributesToRender;
