import React, { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { playlistItemSelector } from '../../../store/playlist/playlist.selectors';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components/native';
import useToken from '../../../hooks/useToken';
import { IStackNavigation } from '../../../navigation/types';
import { PREVIEW_SITE_URL } from '../../../utils/constants/urls';
import { isContentAdminSelector } from '../../../store/auth/auth.selectors';
import useIsPlaylistOwner from '../../../hooks/useIsPlaylistOwner';
import { log, trackEvent } from '../../../store/appActivity/appActivity.slice';
import { setPlaylistToEdit } from '../../../store/createPlaylist/createPlaylist.actions';
import Routes from '../../../navigation/routes';
import { calcHeight, calcWidth, deviceWidth, isWeb } from '../../../utils/dimensions';
import WebView from '../../../components/WebView';
import { Platform, StatusBar } from 'react-native';
import { bitePreviewCloseButtonDataSet, playlistPreviewContainerDataSet } from '../stats.constants';
import ShadowedContainer from '../../../components/ShadowedContainer';
import Button from '../../../components/shared/buttons/Button/Button';
import { IS_IOS } from '../../../utils/constants/env';
import { EAnalyticsContentType } from '../../../store/analytics/analytics.types';
import { contentTypeSelector } from '../../../store/analytics/analytics.selector';

interface IProps extends IStackNavigation {}

const PreviewPlaylist: React.FC<IProps> = ({ navigation, route }) => {
  const { playlistId } = route.params;

  const contentType = useSelector(contentTypeSelector);
  const playlist = useSelector(playlistItemSelector(playlistId));

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const theme = useTheme();

  const { token } = useToken();

  const url = `${PREVIEW_SITE_URL}/${playlist?.is_quiz ? 'quiz' : 'playlists'}/${
    playlist?.id
  }/?token=${token}&rnd=${Date.now()}&isPreview=1`;

  const isContentAdmin = useSelector(isContentAdminSelector);
  const isPlaylistOwner = useIsPlaylistOwner(playlist);
  const isAllowEdit = isPlaylistOwner || isContentAdmin;

  const handleEdit = useCallback(() => {
    if (playlist?.is_quiz) {
      dispatch(
        trackEvent({
          event: 'edit',
          props: { quiz_id: playlist?.id },
        }),
      );
    } else {
      dispatch(
        trackEvent({
          event: 'edit',
          props: { playlist_id: playlist?.id },
        }),
      );
    }
    dispatch(setPlaylistToEdit({ playlist }));
    if (playlist?.is_quiz) {
      navigation.navigate(Routes.EditQuizStack.StackName, { params: { playlistId: playlist.id } });
      return;
    }
    navigation.navigate(Routes.EditPlaylistStack.StackName, {
      screen: Routes.EditPlaylistStack.EditPlaylist,
      params: { playlistId: playlist.id },
    });
  }, [playlist, dispatch, navigation]);

  const handleBack = useCallback(() => {
    dispatch(
      log({
        event: 'PreviewPlaylist.handleBack',
      }),
    );

    navigation.goBack();
  }, [dispatch, navigation]);

  return (
    <S.Container dataSet={playlistPreviewContainerDataSet}>
      <S.WebViewWrap>
        <WebView url={url} />
        <ShadowedContainer>
          <S.BottomPanel>
            {isAllowEdit ? (
              <Button
                isShadowed
                onPress={handleEdit}
                text={t(`analytics.overview.edit${contentType === EAnalyticsContentType.QUIZ ? 'Quiz' : 'Playlist'}`)}
                style={buttonStyle}
                fill={theme.colors.white}
                border={theme.colors.primaryBlue}
                textColor={theme.colors.primaryBlue}
              />
            ) : (
              <S.EmptyView />
            )}

            <Button
              isShadowed
              onPress={handleBack}
              text={t('common.Close')}
              style={buttonStyle}
              fill={theme.colors.white}
              border={theme.colors.primaryBlue}
              textColor={theme.colors.primaryBlue}
              dataSet={bitePreviewCloseButtonDataSet}
            />
          </S.BottomPanel>
        </ShadowedContainer>
      </S.WebViewWrap>
    </S.Container>
  );
};

const buttonStyle = {
  height: calcHeight(45),
  width: calcWidth(150),
};

const S = {
  Container: styled.SafeAreaView`
    flex-grow: 1;
    align-items: center;
    padding-top: ${Platform.OS === 'android' ? StatusBar.currentHeight : 0}px;
  `,
  WebViewWrap: styled.View`
    flex-grow: 1;
    margin-right: -2px;
    margin-bottom: -2px;
    background-color: white;
    overflow: hidden;
    width: ${isWeb ? `${calcWidth(550)}px` : `${deviceWidth}px`};
  `,
  BottomPanel: styled.View`
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${({ theme }) => theme.colors.white};
    height: ${calcHeight(IS_IOS ? 94 : 69)}px;
    width: 100%;
    border-radius: 11px;
    flex-direction: row;
    justify-content: space-between;
    padding: ${calcHeight(12)}px ${calcWidth(27)}px;
  `,
  EmptyView: styled.View``,
};

export default memo(PreviewPlaylist);
