import { IVideoMeta } from '../../hooks/useMedia/useMedia';
import { IBiteItem, TBiteSection } from '../../types/bite';
import { EShareMode } from '../../utils/constants/bite';

const Types = {
  UPDATE_BITE_DATA: 'UPDATE_BITE_DATA',
  EDIT_BITE_INTRO_MEDIA: 'EDIT_BITE_INTRO_MEDIA',
  START_INTRO_MEDIA_PROCESSING: 'START_INTRO_MEDIA_PROCESSING',
  EDIT_BITE_INTRO_VIDEO: 'EDIT_BITE_INTRO_VIDEO',
  EDIT_BITE_QUESTION: 'EDIT_BITE_QUESTION',
  EDIT_BITE_SUMMARY: 'EDIT_BITE_SUMMARY',
  DELETE_BITE_INTRO: 'DELETE_BITE_INTRO',
  DELETE_BITE_SUMMARY: 'DELETE_BITE_SUMMARY',
  DELETE_BITE_QUESTION: 'DELETE_BITE_QUESTION',
  SET_BITE_COVER: 'SET_BITE_COVER',
  SET_BITE_COVER_FROM_URL: 'SET_BITE_COVER_FROM_URL',
  SET_BITE_NAME: 'SET_BITE_NAME',
  TOGGLE_SETTING_ITEM: 'TOGGLE_SETTING_ITEM',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  SET_BITE_ICON: 'SET_BITE_ICON',
  SET_BITE_DESCRIPTION: 'SET_BITE_DESCRIPTION',
  POST_OR_UPDATE_QUIZ_BITE_REQUEST: 'POST_OR_UPDATE_QUIZ_BITE_REQUEST',
  POST_OR_UPDATE_BITE_SUCCESS: 'POST_OR_UPDATE_BITE_SUCCESS',
  POST_OR_UPDATE_BITE_ERROR: 'POST_OR_UPDATE_BITE_ERROR',
  SET_BITE_INTRO: 'SET_BITE_INTRO',
  SET_BITE_INTRO_EDITED: 'SET_BITE_INTRO_EDITED',
  SET_BITE_INTRO_DURATION: 'SET_BITE_INTRO_DURATION',
  SET_BITE_INTRO_ENHANCEMENTS_EDITED: 'SET_BITE_INTRO_ENHANCEMENTS_EDITED',
  SET_BITE_QUESTION_EDITED: 'SET_BITE_QUESTION_EDITED',
  SET_BITE_SUMMARY_EDITED: 'SET_BITE_SUMMARY_EDITED',
  RESET_EDITED_SECTIONS: 'RESET_EDITED_SECTIONS',
  SET_INTRO_TASK: 'SET_INTRO_TASK',
  SET_QUESTION_TASK: 'SET_QUESTION_TASK',
  SET_BITE_INTRO_UPLOADING: 'SET_BITE_INTRO_UPLOADING',
  // SET_BITE_INTRO_UPLOADING_ERROR: 'SET_BITE_INTRO_UPLOADING_ERROR',
  SET_INTRO_MEDIA_PROCESSING_STATUS: 'SET_INTRO_MEDIA_PROCESSING_STATUS',
  CLEAR_CREATE_BITE_STATE: 'CLEAR_CREATE_BITE_STATE',
  SET_INTRO_VIDEO_MEDIA_URI: 'SET_INTRO_VIDEO_MEDIA_URI',
  SET_INTRO_ENHANCEMENTS: 'SET_INTRO_ENHANCEMENTS',
  SET_INTRO_SUBTITLES: 'SET_INTRO_SUBTITLES',
  FETCH_INTRO_SUBTITLES_LINES: 'FETCH_INTRO_SUBTITLES_LINES',
  SET_FETCHING_INTRO_SUBTITLES_LINES: 'SET_FETCHING_INTRO_SUBTITLES_LINES',
  SET_INTRO_SUBTITLES_LINES: 'SET_INTRO_SUBTITLES_LINES',
  DEFINE_INTRO_SUBTITLES_NULL_LOCALE: 'DEFINE_INTRO_SUBTITLES_NULL_LOCALE',
  UPDATE_INTRO_SUBTITLES_LINES: 'UPDATE_INTRO_SUBTITLES_LINES',
  APPLY_INTRO_SUBTITLES_CHANGES: 'APPLY_INTRO_SUBTITLES_CHANGES',
  SET_INTRO_MEDIA_ENABLED: 'SET_INTRO_MEDIA_ENABLED',
  SET_INTRO_ENHANCEMETS_CHANGES: 'SET_INTRO_ENHANCEMETS_CHANGES',
  SAVE_INTRO_ENHANCEMETS_CHANGES: 'SAVE_INTRO_ENHANCEMETS_CHANGES',
  SAVE_INTRO_SUBTITLES_LINES: 'SAVE_INTRO_SUBTITLES_LINES',
  SET_FORCE_CREATE_FLOW: 'SET_FORCE_CREATE_FLOW',
  CLEAR_BITE_COVER: 'CLEAR_BITE_COVER',
  CLEAR_BITE_MEDIA: 'CLEAR_BITE_MEDIA',
  IS_NAME_SUGGESTION_APPLIED: 'IS_NAME_SUGGESTION_APPLIED',
  SET_COVER_KEYWORD_SUGGESTION: 'SET_COVER_KEYWORD_SUGGESTION',
  SET_SUMMARY_SUGGESTION_CARDS: 'SET_SUMMARY_SUGGESTION_CARDS',
  RESET_SUMMARY_SUGGESTION_CARDS: 'RESET_SUMMARY_SUGGESTION_CARDS',
  RESET_QUESTION_SUGGESTION: 'RESET_QUESTION_SUGGESTION',
  SET_QUESTION_SUGGESTION: 'SET_QUESTION_SUGGESTION',
  SELECT_LOCALE: 'SELECT_LOCALE',
  CREATE_BITE_INTRO_VIDEO_BITE_PREPARED: 'CREATE_BITE_INTRO_VIDEO_BITE_PREPARED',
  CREATE_BITE_INTRO_VIDEO_AUDIO_UPLOADED: 'CREATE_BITE_INTRO_VIDEO_AUDIO_UPLOADED',
  CREATE_BITE_INTRO_VIDEO_VIDEO_EXPORTED: 'CREATE_BITE_INTRO_VIDEO_VIDEO_EXPORTED',
  SAVE_SUBTITLES_APPROVED_STATE: 'SAVE_SUBTITLES_APPROVED_STATE',
  SAVE_SUBTITLES_APPROVED_STATE_SUCCESS: 'SAVE_SUBTITLES_APPROVED_STATE_SUCCESS',
  SET_CREATED_FROM_GENERATION: 'SET_CREATED_FROM_GENERATION',
  RETRANSLATE_VOICEOVERS: 'RETRANSLATE_VOICEOVERS',
};

export default Types;

export interface IBiteSettings {
  is_private: boolean;
  discussion_enabled: boolean;
  skip_able: boolean;
  require_correct_answer: boolean;
}

export interface IBitePostBody {
  id?: number;
  organization: number;
  subject: string;
  description: string;
  icon: number;
  cover: number | null; // CoverId
  linked_cover_url: string;
  background: number;
  background_url: string | null;
  language: number;
  is_private: boolean;
  sharing_mode?: EShareMode;
  discussion_enabled: boolean;
  skip_able: boolean;
  bite_sections: TBiteSection[];
}

export enum EIntroMediaProcessingStatus {
  INACTIVE = 'INACTIVE',
  PROCESSING = 'PROCESSING',
  PARTIAL_SUCCESS = 'PARTIAL_SUCCESS',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export type IIntroMediaProcessingStatus = {
  counter: number; // to identify the unique process
  biteId: number;
  all: EIntroMediaProcessingStatus;
  audioExport: EIntroMediaProcessingStatus;
  videoExport: EIntroMediaProcessingStatus;
  s3: EIntroMediaProcessingStatus;
  original: EIntroMediaProcessingStatus;
  enhancements: EIntroMediaProcessingStatus;
  subtitles: EIntroMediaProcessingStatus;
  summarySuggestion: EIntroMediaProcessingStatus;
  questionSuggestion: EIntroMediaProcessingStatus;
  biteNameSuggestion: EIntroMediaProcessingStatus;
  coverSuggestion: EIntroMediaProcessingStatus;
  youtube: EIntroMediaProcessingStatus;
  image: EIntroMediaProcessingStatus;
};

export enum EIntroMediaProcessingFeature {
  ALL = 'ALL',
  S3 = 'S3',
  ORIGINAL = 'ORIGINAL',
  ENHANCEMENTS = 'ENHANCEMENTS',
  SUBTITLES = 'SUBTITLES',
  YOUTUBE = 'YOUTUBE',
  IMAGE = 'IMAGE',
}

export interface IUpdateSharingModeBody {
  sharing_mode: EShareMode;
}

export interface ITrackingMeta {
  biteId: IBiteItem['id'];
  startTs: {
    start: number;
    audio_exporting?: number;
    video_exporting?: number;
    s3: number;
    s3_audio?: number;
    processing_subtitles: number;
    processing_enhancements: number;
  };
  status: Partial<IIntroMediaProcessingStatus>;
  withStatusUpdateOnSubtitlesFailure?: boolean;
  withMetricsTracking?: boolean;
  withSuggestions?: boolean;
  subtitlesProcessingTimeout?: number;
}

export interface ITrackingOptions {
  bites_user_id: number;
  bite_id: null | number;
  task_id: null | string;
  feature: null | EIntroMediaProcessingFeature;
  status: null | EIntroMediaProcessingStatus;
  audio_exporting_ts?: null | number;
  video_exporting_ts?: null | number;
  s3_audio_ts?: null | number;
  s3_ts: null | number;
  original_processing_ts: null | number;
  enhancements_processing_ts: null | number;
  subtitles_processing_ts: null | number;
  total_ts: null | number;
}
export interface ITrackIntroMediaProcessing {
  initialTaskId: string;
  initialCounter: number;
  processId?: string;
  trackingOptions: ITrackingOptions;
  trackingMeta: ITrackingMeta;
  targetLocale?: string;
  videoMeta?: IVideoMeta;
  onError?: () => void;
}
