import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/native';
import Switch from '../../../../components/shared/Switch';
import { TouchableOpacity } from 'react-native';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { trackEvent } from '../../../../store/appActivity/appActivity.slice';
import SettingsTooltip from '../../../../components/shared/SettingsTooltip/SettingsTooltip';
import { calcHeight } from '../../../../utils/dimensions';
import { settingsContainerDataSet } from './BiteSettingsModal.constants';
import { organizationFeaturesSelector } from '../../../../store/org/org.selectors';
import { FEATURE_FLAG_IDS } from '../../../../utils/constants/org';
import { IBiteItem } from '../../../../types/bite';

export interface ISettingsDoneProps {
  is_private: boolean;
  discussion_enabled: boolean;
  skip_able?: boolean;
  require_correct_answer?: boolean;
}

interface IProps {
  isVisible: boolean;
  onClose: ({ is_private, discussion_enabled, skip_able, require_correct_answer }?: ISettingsDoneProps) => void;
  renderLeftButton?: () => React.ReactNode;
  renderRightButton?: () => React.ReactNode;
  isPrivateDefault?: boolean;
  isAllowedDiscussionDefault?: boolean;
  isSkipEnabledDefault?: boolean;
  isRequiredCorrectAnswerDefault?: boolean;
  onDone?: ({ is_private, discussion_enabled, skip_able, require_correct_answer }?: ISettingsDoneProps) => void;
  withSkip?: boolean;
  showLeftButtonOnly?: boolean;
  bite: IBiteItem;
}

const BiteSettingsModal: React.FC<IProps> = ({
  isVisible,
  onClose,
  renderLeftButton,
  renderRightButton,
  isPrivateDefault,
  isAllowedDiscussionDefault,
  isSkipEnabledDefault,
  isRequiredCorrectAnswerDefault,
  onDone,
  withSkip,
  showLeftButtonOnly,
  bite,
}) => {
  const { t } = useTranslation();
  const organizationFeatures = useSelector(organizationFeaturesSelector);
  const requireCorrectAnswerFlag = useMemo(
    () => organizationFeatures?.includes(FEATURE_FLAG_IDS.RequireCorrectAnswer),
    [organizationFeatures],
  );
  const dispatch = useDispatch();

  const [isPrivate, setPrivate] = useState(isPrivateDefault);
  const [isDiscussionEnabled, setDiscussionEnabled] = useState(isAllowedDiscussionDefault);
  const [isSkipEnabled, setIsSkipEnabled] = useState(isSkipEnabledDefault);
  const [isRequiredCorrectAnswer, setIsRequiredCorrectAnswer] = useState(isRequiredCorrectAnswerDefault);

  useEffect(() => {
    setPrivate(isPrivateDefault);
  }, [isPrivateDefault]);

  useEffect(() => {
    setDiscussionEnabled(isAllowedDiscussionDefault);
  }, [isAllowedDiscussionDefault]);

  useEffect(() => {
    setIsSkipEnabled(isSkipEnabledDefault);
  }, [isSkipEnabledDefault]);

  useEffect(() => {
    setIsRequiredCorrectAnswer(isRequiredCorrectAnswerDefault);
  }, [isRequiredCorrectAnswerDefault]);

  const handlePrivatePress = useCallback(() => {
    dispatch(
      trackEvent({
        event: 'change_bite_settings_toggle',
        props: { bite_id: bite?.id, toggle_type: 'is_private', is_on: !isPrivate },
      }),
    );
    setPrivate((prev) => !prev);
  }, [dispatch, bite?.id, isPrivate]);

  const handleDiscussionPress = useCallback(() => {
    setDiscussionEnabled((prev) => !prev);
  }, []);

  const handleSkipPress = useCallback(() => {
    setIsSkipEnabled((prev) => !prev);
  }, []);

  const handleRequireCorrectAnswerPress = useCallback(() => {
    setIsRequiredCorrectAnswer((prev) => !prev);
  }, []);

  const handleDonePress = useCallback(() => {
    if (isAllowedDiscussionDefault !== isDiscussionEnabled) {
      dispatch(
        trackEvent({
          event: 'change_bite_settings_toggle',
          props: { bite_id: bite?.id, toggle_type: 'allow_discussion', is_on: !isDiscussionEnabled },
        }),
      );
    }
    if (isSkipEnabledDefault !== isSkipEnabled) {
      dispatch(
        trackEvent({
          event: 'change_bite_settings_toggle',
          props: { bite_id: bite?.id, toggle_type: 'skip_able', is_on: !isSkipEnabled },
        }),
      );
    }
    if (isRequiredCorrectAnswerDefault !== isRequiredCorrectAnswer) {
      dispatch(
        trackEvent({
          event: 'change_bite_settings_toggle',
          props: { bite_id: bite?.id, toggle_type: 'require_correct_answer', is_on: !isRequiredCorrectAnswer },
        }),
      );
    }
    let payload: ISettingsDoneProps = {
      is_private: isPrivate,
      discussion_enabled: isDiscussionEnabled,
      require_correct_answer: isRequiredCorrectAnswer,
    };

    if (withSkip) {
      payload.skip_able = isSkipEnabled;
    }

    onDone(payload);
  }, [
    isAllowedDiscussionDefault,
    isDiscussionEnabled,
    isSkipEnabledDefault,
    isSkipEnabled,
    isRequiredCorrectAnswerDefault,
    isRequiredCorrectAnswer,
    isPrivate,
    withSkip,
    onDone,
    dispatch,
    bite?.id,
  ]);

  const handleClose = useCallback(() => {
    let payload: ISettingsDoneProps = {
      is_private: isPrivate,
      discussion_enabled: isDiscussionEnabled,
      require_correct_answer: isRequiredCorrectAnswer,
    };

    if (withSkip) {
      payload.skip_able = isSkipEnabled;
    }
    onClose(payload);

    setPrivate(isPrivateDefault);
    setDiscussionEnabled(isAllowedDiscussionDefault);
    setIsSkipEnabled(isSkipEnabledDefault);
    setIsRequiredCorrectAnswer(isRequiredCorrectAnswerDefault);
  }, [
    isAllowedDiscussionDefault,
    isRequiredCorrectAnswerDefault,
    isRequiredCorrectAnswer,
    isDiscussionEnabled,
    isPrivate,
    isPrivateDefault,
    isSkipEnabled,
    isSkipEnabledDefault,
    onClose,
    withSkip,
  ]);

  const hasMultiChoice = useMemo(
    () =>
      bite?.bite_sections?.some(
        (section) =>
          section.type === 'question' && section.questions.some((question) => question?.type === 'multiple choices'),
      ),
    [bite],
  );

  const renderModalContent = useCallback(() => {
    if (showLeftButtonOnly) {
      return <S.ButtonContainer>{renderLeftButton ? renderLeftButton() : <S.EmptyView />}</S.ButtonContainer>;
    }

    return (
      <>
        <S.SettingsContainer dataSet={settingsContainerDataSet}>
          <S.SwitchContainer>
            <S.SwitchLabel>{t('biteCreated.settings.isPrivate')}</S.SwitchLabel>
            <Switch isOn={isPrivate} onToggle={handlePrivatePress} />
          </S.SwitchContainer>
          <S.SwitchContainer>
            <S.SwitchLabel>{t('biteCreated.settings.allowDiscussion')}</S.SwitchLabel>
            <Switch isOn={isDiscussionEnabled} onToggle={handleDiscussionPress} />
          </S.SwitchContainer>
          {withSkip && (
            <S.SwitchContainer>
              <S.SwitchLabel>{t('biteCreated.settings.allowSkip')}</S.SwitchLabel>
              <Switch isOn={isSkipEnabled} onToggle={handleSkipPress} />
            </S.SwitchContainer>
          )}
          {hasMultiChoice && (
            <S.SwitchContainer>
              <S.SwitchLabel>{t('biteCreated.settings.requireCorrectAnswer')}</S.SwitchLabel>
              <Switch
                isOn={isRequiredCorrectAnswer || requireCorrectAnswerFlag}
                onToggle={handleRequireCorrectAnswerPress}
                disabled={requireCorrectAnswerFlag}
              />
            </S.SwitchContainer>
          )}
        </S.SettingsContainer>
        <S.ButtonContainer>
          {renderLeftButton ? renderLeftButton() : <S.EmptyView />}
          {renderRightButton ? (
            renderRightButton()
          ) : (
            <TouchableOpacity onPress={handleDonePress}>
              <S.ButtonText>{t('biteCreated.settings.done')}</S.ButtonText>
            </TouchableOpacity>
          )}
        </S.ButtonContainer>
      </>
    );
  }, [
    handleDiscussionPress,
    handleDonePress,
    handlePrivatePress,
    handleSkipPress,
    handleRequireCorrectAnswerPress,
    requireCorrectAnswerFlag,
    hasMultiChoice,
    isDiscussionEnabled,
    isPrivate,
    isSkipEnabled,
    isRequiredCorrectAnswer,
    renderLeftButton,
    renderRightButton,
    t,
    withSkip,
    showLeftButtonOnly,
  ]);

  return <SettingsTooltip onClose={handleClose} renderContent={renderModalContent} isVisible={isVisible} />;
};

const S = {
  EmptyView: styled.View``,
  ButtonContainer: styled.View`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px;
  `,
  ButtonText: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s15};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SettingsContainer: styled.View`
    margin-bottom: ${calcHeight(50)}px;
  `,
  SwitchLabel: styled.Text`
    font-size: ${({ theme }) => theme.fontSizes.s16};
    color: ${({ theme }) => theme.colors.primaryBlue};
  `,
  SwitchContainer: styled.View`
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  `,
};

export default memo(BiteSettingsModal);
