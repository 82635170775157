import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logError } from '../../../store/appActivity/appActivity.slice';
import styled from 'styled-components';
import defaultTheme from '../../../themes/defaultTheme';
import Button from '../../../components/shared/buttons/Button/Button';
import { Voice, VOICES_LIST } from '../../../utils/voices.constants';
import useAudioPreview from '../../../hooks/useAudioPreview';
import PlayIcon from '../../../assets/icons/videoEditor/text-to-speech-play.svg';
import PauseIcon from '../../../assets/icons/videoEditor/text-to-speech-pause.svg';
import CaretRightIcon from '../../../assets/icons/videoEditor/leftSidebar/caret-right.svg';
import LogoIcon from '../../../assets/icons/logo.svg';
import { gererateTextToSpeech } from '../../../store/api/bites-api/calls/aiGeneration.calls';
import { activeOrganizationSelector } from '../../../store/auth/auth.selectors';
import { ICloudAsset } from '../../../store/cloudAssets/cloudAssets.types';
import { PreviewTextToSpeech } from './preview/PreviewTextToSpeech';
import { PreviewContent, PreviewControls } from './preview/Preview';
import SidebarTitle from './SidebarTitle';
import { v4 as uuid } from 'uuid';
import { createCloudAssetCache, processCloudAsset, setCloudAsset } from '../../../store/cloudAssets/cloudAssets.slice';
import { ITimelineItem } from '../../../store/videoEditor/videoEditor.types';
import {
  addToTimeline,
  addUndo,
  deleteProcessingTimelineItemState,
  removeEmptyTimelineLayers,
  removeTimelineItem,
  removeUndoRedoById,
  saveVideo,
  setProcessingTimelineItemState,
  updateCanvas,
  updateTimelineItem,
  updateTimelineItemLayer,
  updateTimelineItemWithCloudAssetDuration,
} from '../../../store/videoEditor/videoEditor.slice';
import { retryFunctions } from '../../../store/videoEditor/videoEditor.data';
import { getIsItemOnTimeline } from '../utils/getIsItemOnTimeline';
import SelectVoices from './SelectVoices';
import { SidebarProps } from './LeftSidebar';
import {
  selectedTimelineItemIdSelector,
  selectedTimelineItemLayerSelector,
  timelineItemSeletor,
} from '../../../store/videoEditor/videoEditor.selectors';

const MAX_TEXT_LENGTH = 1800;

type Props = SidebarProps & {
  initialText?: string;
  initialVoiceId?: string;
  initialCloudAsset?: ICloudAsset;
  isReplace?: boolean;
  onAdd?: (props: { timelineItemId: string }) => void;
};
const TextToSpeechSidebar = ({
  initialText = '',
  initialVoiceId,
  initialCloudAsset = null,
  setIsPreviewPanelVisible,
  setIsAdditionalPanelVisible,
  isAdditionalPanelVisible,
  isPreviewPanelVisible,
  previewPanelRef,
  additionalPanelRef,
  isReplace,
  onAdd,
}: Props) => {
  const dispatch = useDispatch();

  const org = useSelector(activeOrganizationSelector);
  const selectedTimelineItemId = useSelector(selectedTimelineItemIdSelector);
  const selectedTimelineItem = useSelector(timelineItemSeletor(selectedTimelineItemId));
  const selectedTimelineItemLayer = useSelector(selectedTimelineItemLayerSelector);

  const [text, setText] = useState(initialText);
  const [generatedText, setGeneratedText] = useState(initialText);

  const initialVoice = useMemo(
    () => VOICES_LIST.find((v) => v.id === initialVoiceId) || VOICES_LIST[0],
    [initialVoiceId],
  );
  const [voice, setVoice] = useState(initialVoice);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [previewCloudAsset, setPreviewCloudAsset] = useState<ICloudAsset>(initialCloudAsset);
  const previewVoice = useMemo(
    () => VOICES_LIST.find(({ id }) => previewCloudAsset && id === previewCloudAsset?.sourceMeta?.voiceId) || null,
    [previewCloudAsset],
  );

  const [isSelectVoiceOpen, setIsSelectVoiceOpen] = useState(false);

  const { activeAudioId, handlePlayPreview } = useAudioPreview();

  const isPreview = previewCloudAsset && text === generatedText && previewVoice.id === voice.id;

  const handleGenerate = useCallback(async () => {
    try {
      if (!text || !voice) {
        return;
      }

      if (isPreview) {
        setIsPreviewPanelVisible(true);
        return;
      }

      setGeneratedText(text);
      setIsLoading(true);
      setIsError(false);
      setPreviewCloudAsset(null);

      setIsPreviewPanelVisible(true);

      const { data } = await gererateTextToSpeech({
        voiceId: voice.id,
        text,
        orgId: org.id,
      });

      setPreviewCloudAsset(data.cloudAsset);
    } catch (error) {
      dispatch(
        logError({
          event: 'TextToSpeechSidebar: handleGenerate error',
          data: { error },
        }),
      );
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, isPreview, org.id, setIsPreviewPanelVisible, text, voice]);

  const handleInput = useCallback((e) => {
    if (e.target.value.length > MAX_TEXT_LENGTH) {
      return;
    }
    setText(e.target.value);
  }, []);

  const togglePlayPreview = useCallback(
    (e) => {
      e.stopPropagation();
      handlePlayPreview(voice.id, voice.sample);
      setIsAdditionalPanelVisible(false);
    },
    [handlePlayPreview, setIsAdditionalPanelVisible, voice.id, voice.sample],
  );

  const handleUseItem = useCallback(
    ({ withReplace }: { withReplace?: boolean } = {}) => {
      dispatch(setCloudAsset({ cloudAsset: previewCloudAsset }));

      let start: number;
      let layerId: string;
      if (withReplace && selectedTimelineItem && selectedTimelineItemLayer) {
        start = selectedTimelineItem.start;
        layerId = selectedTimelineItemLayer.id;
        dispatch(
          removeTimelineItem({
            timelineItemId: selectedTimelineItemId,
            fromTimeline: true,
          }),
        );
      }

      const timelineItemId = uuid();
      const timelineItem: Omit<ITimelineItem, 'start' | 'end'> = {
        id: timelineItemId,
        type: previewCloudAsset.fileType as ITimelineItem['type'],
        cloudAssetId: previewCloudAsset.id,
        // generatedMeta: {
        //   generatedFrom: 'text-to-speech',
        //   text,
        //   provider: 'elevenlabs',
        //   voiceId: voice.id,
        // },
      };

      dispatch(
        addToTimeline({
          timelineItem,
          duration:
            previewCloudAsset.fileType === 'video' || previewCloudAsset.fileType === 'audio'
              ? previewCloudAsset.fileMeta.duration
              : null,
          start,
          layerId,
        }),
      );

      dispatch(
        updateTimelineItemLayer({
          timelineItemId,
        }),
      );

      dispatch(removeEmptyTimelineLayers());

      const undoId = uuid();
      dispatch(
        addUndo({
          id: undoId,
        }),
      );

      setIsPreviewPanelVisible(false);
      setPreviewCloudAsset(null);

      setText('');
      setGeneratedText('');

      onAdd?.({ timelineItemId });

      const retryFunctionId = uuid();
      const processingLabel = `Text to speech: ${text}`;
      const applyItemFunction = async () => {
        dispatch(
          setProcessingTimelineItemState({
            timelineItemId,
            label: processingLabel,
            status: 'PROCESSING',
            retryFunctionId,
          }),
        );

        dispatch(
          createCloudAssetCache({
            cloudAsset: previewCloudAsset,
            timelineItems: [timelineItem],
            onCacheReady: () => {
              dispatch(
                removeUndoRedoById({
                  id: undoId,
                }),
              );
              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId,
                  label: processingLabel,
                  status: 'DONE',
                }),
              );

              setTimeout(() => {
                dispatch(
                  deleteProcessingTimelineItemState({
                    timelineItemId,
                  }),
                );
              }, 5000);

              delete retryFunctions[retryFunctionId];
              const isOnTimeline = getIsItemOnTimeline(timelineItemId);

              if (!isOnTimeline) {
                return;
              }

              dispatch(updateTimelineItem(timelineItem));

              dispatch(
                updateTimelineItemWithCloudAssetDuration({
                  timelineItemId,
                }),
              );

              dispatch(updateCanvas({}));

              dispatch(
                addUndo({
                  id: undoId,
                }),
              );

              dispatch(saveVideo({}));
            },
            onFail: (error: any) => {
              dispatch(
                setProcessingTimelineItemState({
                  timelineItemId,
                  label: processingLabel,
                  status: 'ERROR',
                  error: {
                    details: error,
                  },
                  retryFunctionId,
                }),
              );
            },
          }),
        );

        dispatch(processCloudAsset({ cloudAsset: previewCloudAsset }));
      };

      retryFunctions[retryFunctionId] = applyItemFunction;
      applyItemFunction();
    },
    [
      dispatch,
      onAdd,
      previewCloudAsset,
      selectedTimelineItem,
      selectedTimelineItemId,
      selectedTimelineItemLayer,
      setIsPreviewPanelVisible,
      text,
    ],
  );

  const handleReplaceItem = useCallback(() => {
    handleUseItem({ withReplace: true });
  }, [handleUseItem]);

  const handleClosePreview = useCallback(() => {
    setIsPreviewPanelVisible(false);
  }, [setIsPreviewPanelVisible]);

  const handleToggleIsSelectVoiceOpen = useCallback(() => {
    setIsSelectVoiceOpen((prev) => !prev);
    setIsAdditionalPanelVisible(!isSelectVoiceOpen);
  }, [isSelectVoiceOpen, setIsAdditionalPanelVisible]);

  const handleSelectVoice = useCallback(
    (newVoice: Voice) => {
      setVoice(newVoice);
      setIsAdditionalPanelVisible(false);
    },
    [setIsAdditionalPanelVisible],
  );

  useEffect(() => {
    if (!isAdditionalPanelVisible) {
      setIsSelectVoiceOpen(false);
    }
  }, [isAdditionalPanelVisible]);

  return (
    <>
      <S.Title>Narration</S.Title>
      <S.Description>Generate a voiceover from your script</S.Description>
      <S.TextareaContainer>
        <S.Textarea placeholder='Enter text here...' value={text} onInput={handleInput} />
        <S.TextSizeLabel>
          <b>{text.length}</b>/{MAX_TEXT_LENGTH}
        </S.TextSizeLabel>
      </S.TextareaContainer>
      <S.VoiceContainer isSelectVoiceOpen={isSelectVoiceOpen} onClick={handleToggleIsSelectVoiceOpen}>
        <S.VoiceImageContainer>
          <S.VoiceImage src={voice.avatar} />
          <S.PlayButton onClick={togglePlayPreview}>
            {activeAudioId === voice.id ? <PauseIcon /> : <PlayIcon />}
          </S.PlayButton>
        </S.VoiceImageContainer>
        <S.VoiceDescriptionContainer>
          <S.VoiceName>{voice.name}</S.VoiceName>
          <S.VoiceDescription>{voice.description}</S.VoiceDescription>
        </S.VoiceDescriptionContainer>
        <S.CaretIcon>
          <CaretRightIcon />
        </S.CaretIcon>
      </S.VoiceContainer>
      <S.Controls>
        <Button
          text={isPreview ? 'Preview' : 'Generate'}
          textColor={defaultTheme.colors.white}
          border={defaultTheme.colors.primaryBlue}
          fill={defaultTheme.colors.primaryBlue}
          style={buttonStyle}
          isLoading={isLoading}
          disabled={!text || !voice || (isPreviewPanelVisible && isPreview)}
          onPress={handleGenerate}
        />
      </S.Controls>
      {isLoading &&
        previewPanelRef.current &&
        createPortal(
          <>
            <SidebarTitle title='Text to speech' bold={false} />
            <PreviewContent>
              <LogoIcon />
              <div>Clearing throat... Ahem!</div>
              <div>Ready to narrate your masterpiece</div>
            </PreviewContent>
            <PreviewControls onClose={handleClosePreview} />
          </>,
          previewPanelRef.current,
        )}
      {isError &&
        previewPanelRef.current &&
        createPortal(
          <>
            <SidebarTitle title='Text to speech' bold={false} />
            <PreviewContent>
              <div>Failed to generate audio</div>
              <div>Please try again</div>
            </PreviewContent>
            <PreviewControls onClose={handleClosePreview} />
          </>,
          previewPanelRef.current,
        )}
      {previewCloudAsset &&
        previewPanelRef.current &&
        createPortal(
          <PreviewTextToSpeech
            url={previewCloudAsset.storage.url}
            voice={previewVoice}
            onAdd={!isReplace ? handleUseItem : undefined}
            onReplace={isReplace && previewCloudAsset?.id !== initialCloudAsset?.id ? handleReplaceItem : undefined}
            onClose={handleClosePreview}
          />,
          previewPanelRef.current,
        )}

      {isSelectVoiceOpen &&
        additionalPanelRef.current &&
        createPortal(<SelectVoices onSelect={handleSelectVoice} />, additionalPanelRef.current)}
    </>
  );
};
export default TextToSpeechSidebar;

const buttonStyle = {
  paddingLeft: 16,
  paddingRight: 16,
  height: 45,
  width: 133,
};

const S = {
  Title: styled.div`
    padding: 16px;
    font-size: 15px;
    font-weight: 700;
    font-family: ${defaultTheme.fontFamilies.Arimo};
  `,
  Description: styled.div`
    margin: 5px 16px 0;
    color: #575757;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
  TextareaContainer: styled.div`
    position: relative;
    display: flex;
    flex: 1;
    margin: 17px 16px 0;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
  `,
  Textarea: styled.textarea`
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 16px 16px 32px;
    color: #000;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    border-radius: 20px;
    border: 0;
    resize: none;
  `,
  TextSizeLabel: styled.div`
    position: absolute;
    bottom: 14px;
    left: 16px;
    padding: 2px 4px;
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    border-radius: 4px;
    background: white;
  `,
  VoiceContainer: styled.div<{ isSelectVoiceOpen: boolean }>`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    margin: 16px 16px 0;
    padding: 10px 20px;
    border-radius: 20px;
    border: 1px solid #d9d9d9;
    background: ${({ isSelectVoiceOpen }) => (isSelectVoiceOpen ? defaultTheme.colors.lightGray44 : '#fff')};
    cursor: pointer;

    &:hover {
      background: ${defaultTheme.colors.lightGray44};
    }
  `,
  VoiceImageContainer: styled.div`
    position: relative;
    width: 53px;
    height: 53px;
    flex-shrink: 0;
  `,
  VoiceImage: styled.img`
    width: 53px;
    height: 53px;
    border-radius: 53px;
  `,
  PlayButton: styled.div`
    position: absolute;
    top: 50%;
    left: -8px;
    width: 30px;
    height: 30px;
  `,
  VoiceDescriptionContainer: styled.div`
    flex: 1;
  `,
  VoiceName: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
  `,
  VoiceDescription: styled.div`
    color: #313235;
    font-family: ${defaultTheme.fontFamilies.Arimo};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  `,
  CaretIcon: styled.div`
    display: flex;
    align-items: center;
    width: 18px;
  `,
  Controls: styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 16px;
  `,
};
