import { IElementsPanelConfig } from '../../components/shared/ElementsPanel/types';
import { EAppState } from '../../hooks/useOnAppStateChange';

export interface IExternalAction {
  bites_content_type?: string;
  action?: string;
  bites_content_id?: string;
  msg_type?: string;
  section?: string;
  code?: string;
}

export type IAvaitingEnhancements = {
  [biteId: string]: string;
};

export interface IUserOrgPreferences {
  skip_create_bite_flow_overview_screen: null | boolean;
  skip_create_playlist_flow_overview_screen: null | boolean;
  skip_edit_media_overview_modal: null | boolean;
}

type ContextualFeatureFlags = {
  orgIds?: number[];
  userIds?: number[];
  emailPatterns?: string[];
};
type TFeatureFlagValue = boolean | ContextualFeatureFlags;

export enum EFeatureFlag {
  joinDeeplink = 'joinDeeplink',
  multiPartUpload = 'multiPartUpload',
  uploadBanubaFiles = 'uploadBanubaFiles',
  mobileStats = 'mobileStats',
  aiButton = 'aiButton',
  aiStudio = 'aiStudio',
  requireCorrectAnswer = 'requireCorrectAnswer',
}
export interface IFeatureFlags {
  [EFeatureFlag.joinDeeplink]: TFeatureFlagValue;
  [EFeatureFlag.multiPartUpload]: TFeatureFlagValue;
  [EFeatureFlag.uploadBanubaFiles]: TFeatureFlagValue;
  [EFeatureFlag.requireCorrectAnswer]: TFeatureFlagValue;
}

export interface IConfig {
  recaptchaSiteKeys: {
    ios: string;
    android: string;
    web: string;
  };
  recaptchaSiteKeysStaging: {
    ios: string;
    android: string;
    web: string;
  };
  exploreBites: {
    playlistId: number;
  };
  introVideo: {
    mediaType: string;
    mediaURI: string;
  };
  introVideoDesktop: {
    mediaType: string;
    mediaURI: string;
  };
  video: {
    exploreBites: string;
  };
  defaultBrandShareImage: string;
  slowConnectionThreshold: number;
  intercom: {
    eventsWhitelist: string[];
    lockedCommunicationHubArticleId: string;
    restrictedDataArticleId: string;
    aiStudioArticleId: string;
  };
  featureFlags: IFeatureFlags;
  s3MultiPartUpload?: {
    partSize?: number;
    parallelUploads?: number;
  };
}

export type TCurrentFlow =
  | ICurrentFlowBiteCreation
  | ICurrentFlowBiteEdition
  | ICurrentFlowQuizCreation
  | ICurrentFlowQuizEdition;

export type TCurrentFlowAction =
  | Partial<ICurrentFlowBiteCreation>
  | Partial<ICurrentFlowBiteEdition>
  | Partial<ICurrentFlowQuizCreation>
  | Partial<ICurrentFlowQuizEdition>;

export interface IContentLocale {
  locale: string;
  googleTranslate: boolean;
}

export interface IInitialState {
  acrossSessionsId: string;
  currentFlow: TCurrentFlow;
  config: IConfig;
  isCaptchaInitialized: boolean;
  isRequestNotificationPermissionsModalShown: null | boolean;
  userOrgPreferences: IUserOrgPreferences;
  avaitingEnhancements: IAvaitingEnhancements;
  translationsAreInitialized: boolean;
  banubaDraftIds: string[];
  isConnected: boolean;
  appState: EAppState;
  slowConnection: {
    isSlowConnection: boolean;
    isDismissed: boolean;
  };
  biteIntroHelpersConfig: IElementsPanelConfig;
  biteCreatedHelpersConfig: IElementsPanelConfig;
  isGoogleSlidesExplainerVisibleInitial: boolean;
  externalAction: IExternalAction;
  isNavigationStackDisplayed: boolean;
  contentLocales: IContentLocale[];
  userIPInfo: IUserIPInfo;
  isVideoEditorOpen: boolean;
  isFullWidthContent: boolean;
  isMobileStatsExplanationVideoWatched: boolean;
}

export interface IUserIPInfo {
  data: {
    ip: string;
    hostname?: string;
    type: string;
    range_type: {
      type: string;
      description: string;
    };
    connection: {
      asn: number;
      organization: string;
      isp: string;
      range: string;
    };
    location: {
      geonames_id: number;
      latitude: number;
      longitude: number;
      zip: string;
      continent: {
        code: string;
        name: string;
        name_translated: string;
      };
      country: {
        alpha2: string;
        alpha3: string;
        calling_codes: string[];
        currencies: [
          {
            symbol: string;
            name: string;
            symbol_native: string;
            decimal_digits: number;
            rounding: number;
            code: string;
            name_plural: string;
          },
        ];
        emoji: string;
        ioc: string;
        languages: [
          {
            name: string;
            name_native: string;
          },
          {
            name: string;
            name_native: string;
          },
        ];
        name: string;
        name_translated: string;
        timezones: string[];
        is_in_european_union: false;
        fips: string;
        geonames_id: number;
        hasc_id: string;
        wikidata_id: string;
      };
      city: {
        fips?: string;
        geonames_id?: number;
        hasc_id?: string;
        wikidata_id?: string;
        name: string;
        name_translated: string;
      };
      region: {
        fips: string;
        alpha2: string;
        geonames_id: number;
        hasc_id: string;
        wikidata_id: string;
        name: string;
        name_translated: string;
      };
    };
    tlds: string[];
    timezone: {
      id: string;
      current_time: string;
      code: string;
      is_daylight_saving: boolean;
      gmt_offset: number;
    };
    security: {
      is_anonymous?: boolean;
      is_datacenter?: boolean;
      is_vpn?: boolean;
      is_bot?: boolean;
      is_abuser?: boolean;
      is_known_attacker?: boolean;
      is_proxy?: boolean;
      is_spam?: boolean;
      is_tor?: boolean;
      proxy_type?: string;
      is_icloud_relay?: boolean;
      threat_score?: number;
    };
    domains: {
      count?: number;
      domains?: string[];
    };
  };
}

export interface ITrackingProps {
  is_on?: boolean;
  is_draft?: boolean;
  bite_id?: number;
  from_language?: string;
  to_language?: string;
  search_category?: string;
  enhancements_available?: boolean;
  subtitle_available?: boolean;
  subtitles_enabled?: boolean;
  enhancements_enabled?: boolean;
  expanded?: boolean;
  is_skipped?: boolean;
  progress_seconds?: number;
  progress_percentage?: number;
  from?: string;
  media_type?: string;
  page_title?: string;
  playlist_id?: number;
  quiz_id?: number;
  with_video_uri?: boolean;
  entry_point?: string;
  with_draft?: boolean;
  ve_export_success?: boolean;
  ve_video_count?: number;
  ve_video_sources?: number;
  ve_camera_used?: boolean;
  ve_gallery_used?: boolean;
  ve_slide_show_used?: boolean;
  ve_video_resolutions?: string;
  ve_video_duration?: number;
  ve_sdk_version?: string;
  ve_post_processing_effects_used?: boolean | undefined;
  ve_camera_effects_used?: boolean | undefined;
  error?: string;
  tab?: string;
  target_url?: string;
  from_organization_id?: number;
  to_organization_id?: number;
  question_type?: string;
  cover_type?: string;
  number_of_bites?: number;
  toggle_type?: string;
  type?: string;
  from_value?: string;
  to_value?: string;
  external_target_url?: string;
  inspiration_bite?: boolean;
  login_type?: string;
  error_message?: string;
  signup_type?: string;
  sharing_mode?: string;
  search_term?: string;
  bite_ids?: string;
  bites_id?: Array<number> | null;
  status?: string;
  enabled?: boolean;
  trigger?: string;
  is_background?: boolean;
  first_login?: string;
  bites_content_id?: string;
  bites_content_type?: string;
  msg_type?: string;
  title?: string;
  topic?: string;
  fcm_device_token?: string;
  fcm_message_id?: string;
  body?: string;
  org_id?: string;
  ms_teams_org?: boolean;
  number?: number;
  object_id?: number | string;
}

export interface IWebGenericTrackingProps {
  browser_name: string;
  device_orientation: string;
  page_url: string;
  ip: string;
  ip_latitude: number;
  ip_longitude: number;
  referring_url: string;
  timezone_offset: number;
}

export interface INativeGenericTrackingProps {
  carrier: string;
  device_screen_size: string;
  device_unique_identifier: string;
  connectivity_state: string;
  ip: string;
  has_dynamic_island?: boolean;
  has_notch?: boolean;
}

export interface IGenericTrackingProps {
  version: string;
  app_version: string;
  app_env: string;
  across_sessions_id: string;
  bites_session_id: string;
  bites_user_id: number;
  org_id: number;
  lead_name: string;
  username: string;
  user_email: string;
  org_name: string;
  org_create_date: string;
  is_connected: boolean;
  app_state: EAppState;
  date_utc: string;
  timestamp: number;
  event_counter: number;
  is_slow_connection: boolean;
  platform: string;
  origin: string;
  token: string;
}

export interface IAllTrackingProps extends IGenericTrackingProps, Omit<ITrackingProps, 'org_id'> {}

export interface ITrackInfoArgs {
  event: string;
  props?: ITrackingProps;
}

export enum EAppFlow {
  BITE_CREATION = 'BITE_CREATION',
  BITE_EDITION = 'BITE_EDITION',
  QUIZ_CREATION = 'QUIZ_CREATION',
  QUIZ_EDITION = 'QUIZ_EDITION',
}

export interface ICurrentFlowBiteCreation {
  flow: EAppFlow.BITE_CREATION;
  hasIntroVideo: boolean;
  videoUploadedToS3: boolean;
  biteId: number;
}

export interface ICurrentFlowBiteEdition {
  flow: EAppFlow.BITE_EDITION;
  hasIntroVideo: boolean;
  videoUploadedToS3: boolean;
  biteId: number;
}

export interface ICurrentFlowQuizCreation {
  flow: EAppFlow.QUIZ_CREATION;
}

export interface ICurrentFlowQuizEdition {
  flow: EAppFlow.QUIZ_EDITION;
}

export enum ELogType {
  log = 'log',
  error = 'error',
}
