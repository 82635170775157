import { RootState } from '../index';
import { ICloudAsset } from './cloudAssets.types';

export const getState = (state: RootState) => state.cloudAssets;

export const cloudAssetSelector =
  (cloudAssetId: ICloudAsset['id']) =>
  (state: RootState): ICloudAsset =>
    getState(state).cloudAssets[cloudAssetId]?.cloudAsset;

export const cloudAssetCacheReadySelector = (cloudAssetId: ICloudAsset['id']) => (state: RootState) =>
  getState(state).cloudAssetCacheReady[cloudAssetId];

export const toggleIsFavoriteByIdSelector = (cloudAssetId: ICloudAsset['id']) => (state: RootState) =>
  getState(state).toggleIsFavoriteById[cloudAssetId];
export const toggleIsBrandByIdSelector = (cloudAssetId: ICloudAsset['id']) => (state: RootState) =>
  getState(state).toggleIsBrandById[cloudAssetId];

export const temporaryCloudAssetIdsSelector = (state: RootState) => getState(state).temporaryCloudAssetIds;
